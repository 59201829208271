import styled from 'styled-components';
import background from '../../img/background-stepper.png';
import colors from '../../global/theme/colors';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 300;
  justify-content: space-between;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  max-height: 100%;
  width: 22%;
  padding: 2%;

  @media (max-width: 925px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  img {
    height: 40px;
    width: 166.03px;
  }
`;

export const StepsContainer = styled.div`
  width: 100%;
`;

export const InfosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  color: ${colors.light.L100};

  @media only screen and (min-width: 800px) and (max-width: 1121px) {
    flex-direction: column;
  }

  /* @media only screen and (min-width: 1098px) and (max-width: 1098px) {
    flex-direction: column;
  } */

  img {
    height: 20px;
    width: 20px;
  }
  #emailContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }
  a {
    text-decoration: none;
    color: ${colors.light.L100};
  }
`;
