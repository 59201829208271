import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { initialStateAlertInterface } from '../types/types';

const initialState: initialStateAlertInterface = {
  activeAlertError: false,
  activeAlertSuccess: false,
  alertErrorMessage: '',
  alertSuccessMessage: '',
};

export const alertSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    onActiveAlertError: (state, action) => {
      state.alertErrorMessage = action.payload;
      state.activeAlertError = true;
    },
    onActiveAlertSuccess: (state, action) => {
      state.alertSuccessMessage = action.payload;
      state.activeAlertSuccess = true;
    },
    onDisableAlertError: (state, action) => {
      state.alertErrorMessage = '';
      state.activeAlertError = false;
    },
    onDisableAlertSuccess: (state, action) => {
      state.alertSuccessMessage = '';
      state.activeAlertSuccess = false;
    },
  },
});

export const alert = (state: RootState) => state.alert;

export const {
  onActiveAlertError,
  onActiveAlertSuccess,
  onDisableAlertError,
  onDisableAlertSuccess,
} = alertSlice.actions;

export default alertSlice.reducer;
