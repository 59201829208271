import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import colors from '../../global/theme/colors';

interface Props {
  lastStep?: boolean;
  disabled?: boolean;
  onClick: () => void;
  children?: any;
  loading?: boolean;
  className?: string;
  icon?: any;
  width?: number | string;
  id?: string;
}

export const ConfirmButton: FC<Props> = ({
  lastStep,
  disabled,
  onClick,
  children,
  loading,
  className,
  icon,
  width,
  id,
  ...params
}) => {
  return (
    <Box
      id={id}
      onClick={() => disabled ? {} : onClick()}
      className={className}
      {...params}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: icon ? 'space-evenly' : 'center',
        backgroundColor: disabled
          ? colors.brandBlue.BB100
          : colors.brandBlue.BB700,
        width: width ? width : 188,
        height: 52,
        cursor: disabled ? 'default' : 'pointer',
        borderRadius: 2,
        ':hover': {
          backgroundColor: disabled
            ? colors.brandBlue.BB100
            : colors.brandBlue.BB600,
        },
        '@media (max-width: 600px)': {
          width: 280,
          padding: 1 // Ajuste o valor de width para dispositivos móveis conforme necessário                    
        },
      }}
    >
      <Typography
        sx={{
          color: colors.light.L100,
          fontFamily: 'Poppins, sans-serif',
          fontSize: 18,
          fontWeight: 400,
        }}
      >
        {children}
      </Typography>
      {icon}
    </Box>
  );
};

export const ResendButton: FC<Props> = ({
  lastStep,
  disabled,
  onClick,
  children,
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: disabled
          ? colors.brandBlue.BB100
          : colors.brandBlue.BB700,
        width: 186,
        height: 50,
        cursor: disabled ? 'default' : 'pointer',
        borderRadius: 0.5,
        ':hover': {
          backgroundColor: disabled
            ? colors.brandBlue.BB100
            : colors.brandBlue.BB600,
        },
      }}
    >
      <Typography
        sx={{
          color: colors.light.L100,
          fontFamily: 'Poppins, sans-serif',
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};
