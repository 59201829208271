import styled from 'styled-components';

export const MobileBarContainer = styled.div`
  max-width: 92vw;
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
