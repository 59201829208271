import { Box, FormControl, InputLabel, MenuItem, Modal } from '@mui/material';
import { Select } from '../../@condofy-components/select';
import { Header, HeaderModal, Label, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBox, MainContainer, ModalContainerFlexible, SubContainer } from '../../global/styles/style';
import colors from '../../global/theme/colors';
import { TextField } from '../../@condofy-components/textField';
import { cpfMask } from '../../utils/masks';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { logoutUser, onCpfChange, onGoalChange, onRoleChange, user } from '../../redux/userSlice';
import { auth, emailOrCpfCheck, logoutAuth, onEmailOrCpfChange } from '../../redux/authSlice';
import { useEffect, useState } from 'react';
import { PreviouslyButton } from '../../@condofy-components/previouslyButton';
import { NextButton } from '../../@condofy-components/nextButton';
import { CPFIsValid } from '../../utils';
import { goesToStep } from '../../redux/stepperSlice';

export const PersonalData = () => {
  const { cpf, role, goal } = useAppSelector(user);
  const { isTemporary } = useAppSelector(auth);
  const dispatch = useAppDispatch();
  const [showModalError, setShowModalError] = useState(false);
  const [showModalCpfInvalid, setShowModalCpfInvalid] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const checkCpf = () => {
    console.log(cpf)
    if (cpf === '99999999999') {
      dispatch(onCpfChange(''))
    }
  }

  useEffect(() => {
    checkCpf()
  }, [])

  const cpfAlreadyExist = () => {
    if (!CPFIsValid(cpf)) {
      console.log('log')
      setShowModalCpfInvalid(true);
      return;
    }
    dispatch(emailOrCpfCheck({ value: cpf }))
      .then((response) => {
        if (response.payload) {
          setShowModalError(true)
        } else {
          setShowModalError(false)
        }
      })
      .catch(() => {
        setShowModalError(false);
        console.log('dd');
      })
  }

  useEffect(() => {
    if (cpf.length === 14) {
      cpfAlreadyExist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf]);



  return (
    <MainContainer data-testid="personal-data">
      <SubContainer>
        <Header style={{ margin: 0 }}>Dados Pessoais</Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          Precisamos saber um pouco mais sobre você, vamos lá?
        </SubHeader>
        <FlexibleBox>
          <Label style={{ marginTop: '16px' }}>Seu CPF</Label>
          <TextField
            placeholder="Seu CPF"
            sx={{ marginTop: '16px' }}
            disabled={!isTemporary && cpf && CPFIsValid(cpf)}
            value={cpfMask(cpf)}
            onChange={(e: any) => dispatch(onCpfChange(e.target.value))}
          />
          <Label style={{ marginTop: '16px' }}>Seu papel no condomínio?</Label>
          <FormControl sx={{ marginTop: '16px', width: '100%' }}>
            {role === null && (
              <InputLabel sx={{ color: colors.dark.D200 }}>Selecione</InputLabel>
            )}
            <Select
              onChange={(e: any) => dispatch(onRoleChange(e.target.value))}
              value={role}
              data-testid='select-type'
            >
              <MenuItem key={0} value={3}>
                Proprietário
              </MenuItem>
              <MenuItem key={1} value={4}>
                Inquilino
              </MenuItem>
              <MenuItem key={1} value={5}>
                Imobiliária
              </MenuItem>
            </Select>
          </FormControl>
        </FlexibleBox>
        <Label style={{ marginTop: '16px' }}>
          Qual seu principal objetivo ao contratar a Condofy?
        </Label>
        <FlexibleBox>
          <FormControl sx={{ marginTop: '16px', width: '100%' }}>
            {goal === null && (
              <InputLabel sx={{ color: colors.dark.D200 }}>Selecione</InputLabel>
            )}
            <Select
              onChange={(e: any) => dispatch(onGoalChange(e.target.value))}
              value={goal}
              data-testid='select-goal'
            >
              <MenuItem key={1} value={'Reduzir Custos'}>
                Reduzir Custos
              </MenuItem>
              <MenuItem key={2} value={'Melhorar a gestão'}>
                Melhorar a gestão
              </MenuItem>
              <MenuItem key={1} value={'Trazer benefícios'}>
                Trazer benefícios
              </MenuItem>
              <MenuItem key={2} value={'Outros'}>
                Outros
              </MenuItem>
            </Select>
          </FormControl>
        </FlexibleBox>
        <Modal open={showModalError} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ModalContainerFlexible>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <HeaderModal>
                Já existe uma conta usando esse CPF
              </HeaderModal>
              <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
                Deseja usar as suas credenciais?
              </SubHeader>
            </Box>
            <Box sx={{ display: 'flex', width: '90%', justifyContent: 'space-between' }}>
              <PreviouslyButton
                marginLeft='0'
                disappear={false}
                customText='Não'
                disabled={false}
                onClick={() => {
                  dispatch(onCpfChange(''))
                  setShowModalError(false)
                }}
              />
              <NextButton
                disappear={false}
                customText='Sim'
                disabled={false}
                onClick={() => {
                  dispatch(logoutAuth({ cleanEoC: false }))
                  dispatch(onEmailOrCpfChange(cpf))
                  dispatch(logoutUser())
                  dispatch(goesToStep(1))
                  setShowModalError(false)
                }}
              />
            </Box>
          </ModalContainerFlexible>
        </Modal>
        <Modal open={showModalCpfInvalid} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ModalContainerFlexible>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <HeaderModal>
                CPF inválido
              </HeaderModal>
              <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
                Tente novamente com um CPF válido
              </SubHeader>
            </Box>
            <Box sx={{ display: 'flex', width: '90%', justifyContent: 'center' }}>
              <NextButton
                disappear={false}
                customText='Fechar'
                disabled={false}
                onClick={() => {
                  dispatch(onCpfChange(''))
                  setShowModalCpfInvalid(false)
                }}
              />
            </Box>
          </ModalContainerFlexible>
        </Modal>
      </SubContainer>
      <NextAndPrevComponents disableNext={!cpf || !role || !goal} returnText='Logout' isLogout />
    </MainContainer>
  );
};
