import {
  Box,
  Modal,
  Typography,
} from '@mui/material';
import { Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import colors from '../../global/theme/colors';
import { TextField, TextFieldMaps } from '../../@condofy-components/textField';
import { cnpjMask } from '../../utils/masks';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getCnpjInfo, onCleanCnpjInfos, onCnpjChange, onCustomCondoNameChange, onFullAddressChange, setDoNotKnowCnpj, user } from '../../redux/userSlice';
import { useEffect, useState } from 'react';
import { auth, cnpjCheck, setLoading } from '../../redux/authSlice';
import { onActiveAlertError } from '../../redux/alertSlice';
import { FlexibleBox, MainContainer, ModalContainerFlexible, SubContainer } from '../../global/styles/style';
import { CNPJIsValid } from '../../utils';
import { NextButton } from '../../@condofy-components/nextButton';
import { BpCheckbox } from '../../@condofy-components/checkbox';

export const AboutCondominium = () => {
  const {
    cnpj,
    legalName,
    city,
    number,
    state,
    street,
    district,
    zipCode,
    tradeName,
    doNotKnowCnpj,
    customCondoName
  } = useAppSelector(user);
  const dispatch = useAppDispatch();
  const { loadingAuth } = useAppSelector(auth);
  const [showModalError, setShowModalError] = useState(false);
  const [showModalCnpjInvalid, setShowModalCnpjInvalid] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const cpfAleradyExist = () => {
    if (!CNPJIsValid(cnpj)) {
      setShowModalCnpjInvalid(true);
      return;
    }
    dispatch(cnpjCheck({ cnpj }))
      .then((response) => {
        console.log(response);
        if (response.payload) {
          setShowModalError(true)
        } else {
          if (cnpj.length === 18) {
            getCnpj()
          } else if (cnpj.length < 18) {
            dispatch(onCleanCnpjInfos({}))
          }
          setShowModalError(false)
        }
      })
      .catch(() => {
        setShowModalError(false);
      })
  }

  useEffect(() => {
    if (cnpj.length === 18) {
      cpfAleradyExist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpj]);

  const getCnpj = () => {
    dispatch(setLoading(true));
    dispatch(getCnpjInfo({ cnpj }))
      .then((response) => {
        if (response.payload) {
          dispatch(setLoading(false));
        } else {
          dispatch(onActiveAlertError("CNPJ inválido."))
          dispatch(onCnpjChange(''))
          dispatch(setLoading(false));
        }
      })
  }

  useEffect(() => {
    if (doNotKnowCnpj) {
      dispatch(onCnpjChange(''));
      dispatch(onCleanCnpjInfos({}));
    } else {
      dispatch(onCustomCondoNameChange(''));
      dispatch(onFullAddressChange(''));
      dispatch(onCleanCnpjInfos({}));
    }
  }, [doNotKnowCnpj])

  return (
    <MainContainer data-testid='about-condominium'>
      <SubContainer>
        <Header style={{ margin: 0 }}>Sobre seu condomínio</Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          Digite o número de CNPJ do seu condomínio e revise os dados antes de
          continuar.
        </SubHeader>
        <Label style={{ marginTop: '16px' }}>
          Qual o CNPJ do seu condomínio?
        </Label>
        <FlexibleBox>
          <TextField
            data-testid="cnpj-condominium"
            placeholder="CNPJ do condomínio"
            sx={{ marginTop: '16px' }}
            value={cnpjMask(cnpj)}
            disabled={doNotKnowCnpj}
            onChange={(e: any) => dispatch(onCnpjChange(e.target.value))}
          />
          {cnpj.length === 18
            && !loadingAuth
            && city
            && number
            && state
            && street
            && district
            && zipCode ? (
            <Box
              sx={{
                display: 'flex',
                height: 88,
                marginBottom: 2,
                marginTop: '5px',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.brandBlue.BB700,
                  width: 2,
                  height: '100%',
                  borderTopLeftRadius: 2,
                  borderBottomLeftRadius: 2
                }}
              ></Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  backgroundColor: colors.light.L200,
                  paddingLeft: 2,
                }}
              >
                <Typography
                  sx={{
                    color: colors.dark.D600,
                    fontFamily: 'Manrope, sans-serif',
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                >
                  {tradeName ? tradeName : legalName ? legalName : 'Sem nome'}
                </Typography>
                <Typography
                  sx={{
                    color: colors.dark.D300,
                    fontFamily: 'Manrope, sans-serif',
                    fontWeight: 400,
                    fontSize: 14,
                  }}
                >
                  {street}, {number}, {district}, {city}, {state}
                </Typography>
              </Box>
            </Box>
          ) : (<BpCheckbox
            color='rgba(0, 25, 71, 0.734)'
            onChange={() => {
              if (doNotKnowCnpj) {
                dispatch(setDoNotKnowCnpj(false));
              } else {
                dispatch(setDoNotKnowCnpj(true));
              }
            }}
            value={doNotKnowCnpj}
            label='Não sei o CNPJ do condomínio'
            fontFamily='Poppins, sans-serif'
            fontWeight={500}
          />)}
        </FlexibleBox>
        {doNotKnowCnpj && (
          <>
            <FlexibleBox>
              <Label style={{ marginTop: '16px' }}>
                Qual o nome do condomínio?
              </Label>
              <TextField
                placeholder="Nome do condomínio"
                sx={{ marginTop: '16px' }}
                value={customCondoName}
                onChange={(e: any) => dispatch(onCustomCondoNameChange(e.target.value))}
              />
            </FlexibleBox>
            <FlexibleBox>
              <Label style={{ marginTop: '16px' }}>
                Qual o endereço do condomínio?
              </Label>
              <TextFieldMaps
                placeholder="Endereço do condomínio"
                sx={{ marginTop: '16px' }} />
            </FlexibleBox>
          </>
        )}
        <Modal open={showModalError} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ModalContainerFlexible>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <Header>
                Esse CNPJ já está em uso
              </Header>
              <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
                Por favor digite um novo CNPJ.
              </SubHeader>
            </Box>
            <Box sx={{ display: 'flex', width: '90%', justifyContent: 'center' }}>
              <NextButton
                disappear={false}
                customText='Ok'
                disabled={false}
                onClick={() => {
                  dispatch(onCnpjChange(''))
                  setShowModalError(false)
                }}
              />
            </Box>
          </ModalContainerFlexible>
        </Modal>
        <Modal open={showModalCnpjInvalid} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ModalContainerFlexible>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <Header>
                CNPJ inválido
              </Header>
              <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
                Tente novamente com um CNPJ válido.
              </SubHeader>
            </Box>
            <Box sx={{ display: 'flex', width: '90%', justifyContent: 'center' }}>
              <NextButton
                disappear={false}
                customText='Fechar'
                disabled={false}
                onClick={() => {
                  dispatch(onCnpjChange(''))
                  setShowModalCnpjInvalid(false)
                }}
              />
            </Box>
          </ModalContainerFlexible>
        </Modal>
      </SubContainer>
      <NextAndPrevComponents
        disableNext={
          doNotKnowCnpj
            ?
            (
              !customCondoName ||
              !city ||
              !number ||
              !state ||
              !street ||
              !district ||
              !zipCode
            ) :
            (
              loadingAuth ||
              !cnpj
              || !city
              || !district
              || !state
            )} />
    </MainContainer>
  );
};
