import styled from 'styled-components';
import colors from '../../global/theme/colors';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 300;
  justify-content: flex-start;
  align-items: center;
  height: 90%;
  min-height: fit-content;
  flex: 1;
  padding: 6% 8% 0 9%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  @media only screen and (min-height: 690px) and (max-height: 720px) {
    padding: 5% 8% 0 9%;
  }
  @media only screen and (min-height: 0px) and (max-height: 690px) {
    padding: 2% 8% 0 9%;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  img {
    height: 40px;
    width: 166.03px;
  }
`;

export const StepsContainer = styled.div`
  width: 100%;
`;

export const InfosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  color: ${colors.light.L100};
  img {
    height: 20px;
    width: 20px;
  }
  #emailContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }
  a {
    width: 42%;
    text-decoration: none;
    color: ${colors.light.L100};
  }
`;
