import { Box } from "@mui/material";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import colors from "../../global/theme/colors";
import { Header, SubHeader } from "../../@condofy-components/typoFonts";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { checkStripeStatus } from "../../redux/userSlice";
import AnimatedIcon from "../../@condofy-components/refreshIcon";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';


export const ReturnStripe = () => {
  const { session_id } = useParams();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(0);
  const navigation = useNavigate();

  const checkStatus = () => {
    dispatch(checkStripeStatus({ session_id: session_id?.slice(11) as string }))
      .then((data: any) => {
        if (data.payload && data.payload.data && data.payload.data.status === 'complete') {
          setStatus(1)
          setTimeout(() => navigation('/'), 3000)
        }
        console.log(data)
      });
  }

  useEffect(() => {
    checkStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Header
        style={{ margin: 0 }}>
        {status === 0
          ? 'Carregando informações'
          : status === 1
            ? 'Assinatura feita com sucesso!'
            : 'Algo deu errrado no cadastro do meio de pagamento'
        }
      </Header>
      <SubHeader
        style={{ margin: '0px 0px 18px 0px' }}>
        {status === 0
          ? 'Carregando informações'
          : status === 1 ? 'Em segundos o processo vai dar sequência'
            : 'Tente novamente.'
        }
      </SubHeader>
      {status === 0
        ?
        (<AnimatedIcon />)
        :
        status === 1
          ?
          (<CheckCircleRoundedIcon sx={{ color: colors.brandBlue.BB700, fontSize: 100 }} />)
          :
          (<CancelRoundedIcon sx={{ color: colors.red, fontSize: 100 }} />)
      }
    </Box>
  );
};
