import { TextField } from '../../@condofy-components/textField';
import { Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBox, MainContainer, SubContainer } from '../../global/styles/style';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { auth, loginUser, onEmailOrCpfChange, simpleUserTest } from '../../redux/authSlice';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { onCpfChange } from '../../redux/userSlice';
import { adaptiveMask } from '../../utils/masks';
import { advanceStep, goesToStep } from '../../redux/stepperSlice';
import { onActiveAlertError } from '../../redux/alertSlice';

export const CheckEmail = () => {
  const dispatch = useAppDispatch();
  const { emailOrCpf } = useAppSelector(auth);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        console.log(buttonElement)
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const verifyEmail = () => {
    if (!emailOrCpf) { return }
    dispatch(simpleUserTest({ emailOrCpf }))
      .then((response: any) => {
        const treatedResponse = response.payload.data.data
        console.log(treatedResponse)
        if (treatedResponse.id.length > 0) {
          if (treatedResponse.isTemporary) {
            login();
          } else {
            dispatch(advanceStep());
          }
        }
      })
      .catch(() => {
        console.log('error')
        dispatch(goesToStep(2));
      })
  }

  const login = async () => {
    dispatch(loginUser({ email: emailOrCpf, password: emailOrCpf }))
      .then((response: any) => {
        if (response.payload) {
          if (response.payload.profile) {
            dispatch(
              onCpfChange(
                response.payload.profile.socialSecurityNumber === ''
                  ?
                  ''
                  :
                  response.payload.profile.socialSecurityNumber
              )
            )
          }

          dispatch(goesToStep(5));

        } else {
          dispatch(onActiveAlertError("Erro, tente novamente."))
        }
        return response
      }).catch((error) => {
        console.log(error)
        throw new Error('Login error')
      })
  }

  return (
    <MainContainer key={1} data-testid="check-email">
      <SubContainer>
        <Header style={{ margin: 0 }}>Dados Pessoais</Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          Precisamos saber um pouco mais sobre você, vamos lá?
        </SubHeader>
        <FlexibleBox>
          <Label>Seu Email ou CPF</Label>
          <TextField
            id='emailOrCpf'
            placeholder="Seu Email ou CPF"
            sx={{ marginTop: '16px' }}
            value={emailOrCpf}
            onChange={(e: any) => dispatch(onEmailOrCpfChange(adaptiveMask(e.target.value)))}
          />
        </FlexibleBox>
      </SubContainer>
      <NextAndPrevComponents
        onNext={verifyEmail}
        hideReturn
        disableNext={!emailOrCpf}
      />
    </MainContainer>
  );
};
