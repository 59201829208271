import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { condoCreateBody, condoCreateBodyNew, initialStateAuthInterface } from '../types/types';
import { condofy, condofyPrivate } from '../api/base';
import { cleanStringIfIfCpf, getToken, stringCleaner } from '../utils';

const initialState: initialStateAuthInterface = {
  id: null,
  emailConfirmed: null,
  phoneNumberConfirmed: null,
  isTemporary: null,
  loadingAuth: false,
  token: '',
  email: '',
  phone: '',
  userName: '',
  condoTypes: [],
  isTested: false,
  creatingLoading: false,
  emailOrCpf: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetState: () => initialState,
    logoutAuth: (state, action) => {
      state.id = null;
      state.emailConfirmed = null;
      state.phoneNumberConfirmed = null;
      state.isTemporary = null;
      state.loadingAuth = false;
      state.token = '';
      state.email = '';
      state.phone = '';
      if (action.payload.cleanName) {
        state.userName = '';
      }
      state.condoTypes = [];
      state.isTested = false;
      if (action.payload.cleanEoC) {
        state.emailOrCpf = '';
      }
    },
    logoutAuthPhone: (state, action) => {
      state.phoneNumberConfirmed = null;
      state.phone = '';
    },
    onEmailOrCpfChange: (state, action) => {
      state.emailOrCpf = action.payload;
    },
    onEmailChange: (state, action) => {
      state.email = action.payload;
    },
    onPhoneChange: (state, action) => {
      state.phone = action.payload;
    },
    onNameChange: (state, action) => {
      state.userName = action.payload;
    },
    setLoading: (state, action) => {
      state.loadingAuth = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(userTest.pending, state => {
      state.loadingAuth = true;
    });
    builder.addCase(userTest.fulfilled, (state, action) => {
      if (action.payload && action.payload.data) {
        state.id = action.payload.data.data.id;
        state.emailConfirmed = action.payload.data.data.emailConfirmed;
        state.phoneNumberConfirmed =
          action.payload.data.data.phoneNumberConfirmed;
        state.isTemporary = action.payload.data.data.isTemporary;
        state.phone = action.payload.data.data.phoneNumber;
        state.userName = action.payload.data.data.name;
        state.isTested = true;
      }
      state.loadingAuth = false;
    });
    builder.addCase(simpleUserTest.pending, state => {
      state.loadingAuth = true;
    });
    builder.addCase(simpleUserTest.fulfilled, (state, action) => {
      state.loadingAuth = false;
    });
    builder.addCase(userTest.rejected, state => {
      state.id = '';
      state.emailConfirmed = false;
      state.phoneNumberConfirmed = false;
      state.isTemporary = false;
    });
    builder.addCase(emailOrCpfCheck.pending, state => {
      state.loadingAuth = true;
    });
    builder.addCase(emailOrCpfCheck.fulfilled, state => {
      state.loadingAuth = false;
    });
    builder.addCase(registerNewUser.pending, state => {
      state.loadingAuth = true;
    });
    builder.addCase(registerNewUser.fulfilled, (state, action) => {
      if (action.payload && action.payload.data) {
        state.id = action.payload.data.data.id;
        state.emailConfirmed = action.payload.data.data.emailConfirmed;
        state.phoneNumberConfirmed =
          action.payload.data.data.phoneNumberConfirmed;
        state.isTemporary = true;
      }
      state.loadingAuth = false;
    });
    builder.addCase(registerNewUser.rejected, (state, action) => { });
    builder.addCase(loginUser.pending, state => {
      state.loadingAuth = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload && action.payload.login && action.payload.profile) {
        state.token = action.payload.login.accessToken;
        window.sessionStorage.setItem('token', state.token);
        state.email = action.payload.profile.email;
        state.id = action.payload.profile.id;
        if (action.payload.profile.phoneNumber) {
          state.phone = action.payload.profile.phoneNumber;
        }
        state.emailConfirmed = action.payload.profile.emailConfirmed;
        state.userName = action.payload.profile.name;
        state.phoneNumberConfirmed =
          action.payload.profile.phoneNumberConfirmed;
      }
      state.loadingAuth = false;
    });
    builder.addCase(confirmPhone.pending, state => {
      state.loadingAuth = true;
    });
    builder.addCase(confirmPhone.fulfilled, (state, action) => {
      state.loadingAuth = false;
    });
    builder.addCase(getCondoTypes.fulfilled, (state, action) => {
      if (action.payload && action.payload.data && action.payload.data.data) {
        state.condoTypes = action.payload.data.data;
      }
    });
    builder.addCase(changePassword.pending, state => {
      state.loadingAuth = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loadingAuth = false;
    });
    builder.addCase(finishCondominium.pending, state => {
      state.creatingLoading = true;
    });
    builder.addCase(finishCondominium.fulfilled, (state, action) => {
      state.creatingLoading = false;
    });
    builder.addCase(updatePhone.pending, state => {
      state.loadingAuth = true;
    });
    builder.addCase(updatePhone.fulfilled, (state, action) => {
      state.loadingAuth = false;
    });
    builder.addCase(updateNameAndEmail.pending, state => {
      state.loadingAuth = true;
    });
    builder.addCase(updateNameAndEmail.fulfilled, (state, action) => {
      state.loadingAuth = false;
    });
  },
});

export const {
  onEmailChange,
  onPhoneChange,
  setLoading,
  onNameChange,
  logoutAuth,
  resetState,
  onEmailOrCpfChange,
  logoutAuthPhone
} = authSlice.actions;

export const userId = (state: RootState) => state.auth.id;
export const userEmailConfirmed = (state: RootState) =>
  state.auth.emailConfirmed;
export const userPhoneNumberConfirmed = (state: RootState) =>
  state.auth.phoneNumberConfirmed;
export const userIsTemporary = (state: RootState) => state.auth.isTemporary;
export const userLoadingAuth = (state: RootState) => state.auth.loadingAuth;
export const auth = (state: RootState) => state.auth;

export const userTest = createAsyncThunk(
  'auth-user/userTest',
  async ({ emailOrCpf }: { emailOrCpf: string }) => {
    try {
      let loginResponse = await condofy.get(
        `/users/${cleanStringIfIfCpf(emailOrCpf)}/state`
      );

      return loginResponse;
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const simpleUserTest = createAsyncThunk(
  'auth-user/simpleUserTest',
  async ({ emailOrCpf }: { emailOrCpf: string }) => {
    try {
      let loginResponse = await condofy.get(
        `/users/${cleanStringIfIfCpf(emailOrCpf)}/state`
      );

      return loginResponse;
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const emailOrCpfCheck = createAsyncThunk(
  'auth-user/emailOrCpfCheck',
  async ({ value }: { value: string }) => {
    try {
      console.log(value, 'sdfsd');
      await condofy.get(`/users/${cleanStringIfIfCpf(value)}/state`);

      return true;
    } catch (error: any) {
      return false;
    }
  }
);

export const emailOrCpfCheckWLoading = createAsyncThunk(
  'auth-user/emailOrCpfCheckWLoading',
  async ({ value }: { value: string }) => {
    try {
      console.log(value, 'sdfsd');
      await condofy.get(`/users/${cleanStringIfIfCpf(value)}/state`);

      return true;
    } catch (error: any) {
      return false;
    }
  }
);

export const cnpjCheck = createAsyncThunk(
  'auth-user/cnpjCheck',
  async ({ cnpj }: { cnpj: string }) => {
    try {
      await condofy.head(`/v1/condominiums/${stringCleaner(cnpj, 14)}/exists`);

      return true;
    } catch (error: any) {
      return false;
    }
  }
);

export const testEmailWithoutLoading = createAsyncThunk(
  'auth-user/testEmailWithoutLoading',
  async ({ email }: { email: string }) => {
    try {
      let loginResponse = await condofy.get(`/users/${email}/state`);

      return loginResponse;
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const registerNewUser = createAsyncThunk(
  'auth-user/registerNewUser',
  async ({
    email,
    name,
    phone,
  }: {
    email: string;
    name: string;
    phone?: string;
  }) => {
    try {
      let createResponse = await condofy.post(`/accounts/sign-up-starter`, {
        name: name,
        email: email,
        phoneNumber: phone ? stringCleaner(phone, 11) : null,
      });
      console.log({ createResponse });
      return createResponse;
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const loginUser = createAsyncThunk(
  'auth-user/login',
  async ({ email, password }: { email: string; password: string }) => {
    try {
      let loginResponse = await condofy.post(`/accounts/authenticate`, {
        login: cleanStringIfIfCpf(email),
        password,
      });
      console.log({ loginResponse });
      let profileResponse = await condofy.get(`/accounts/profile`, {
        headers: {
          Authorization: `Bearer ${loginResponse.data.data.accessToken}`,
        },
      });

      console.log({ profileResponse });

      return {
        login: loginResponse.data.data,
        profile: profileResponse.data.data,
      };
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const updateNameAndEmail = createAsyncThunk(
  'auth-user/updateNameAndEmail',
  async ({ email, name, id }: { email: string; name: string; id: string }) => {
    try {
      let response = await condofy.post(`/accounts/sign-up-starter/${id}`, {
        name,
        email,
      });

      return response;
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const resendEmailToken = createAsyncThunk(
  'auth-user/resendEmailToken',
  async ({ email }: { email: string }) => {
    try {
      let response = await condofy.post(
        `/accounts/${email}/resend-email-confirmation-token`
      );

      return response;
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const resendPhoneToken = createAsyncThunk(
  'auth-user/resendPhoneToken',
  async ({ email }: { email: string }) => {
    try {
      let response = await condofy.post(
        `/accounts/${email}/resend-phone-number-confirmation-token`
      );

      return response;
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const updatePhone = createAsyncThunk(
  'auth-user/updatePhone',
  async ({ phone, id }: { phone: string; id: string }) => {
    try {
      let response = await condofy.post(
        `/accounts/${id}/register-phone-number`,
        {
          phoneNumber: stringCleaner(phone, 11),
        }
      );

      return response;
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const confirmPhone = createAsyncThunk(
  'auth-user/confirmPhone',
  async ({
    phone,
    token,
    id,
  }: {
    phone: string;
    token: string;
    id: string;
  }) => {
    try {
      let response = await condofy.post(
        `/accounts/${id}/confirm-phone-number`,
        {
          phoneNumber: stringCleaner(phone, 11),
          token,
        }
      );
      if (response.status === 200 || response.status === 204) {
        return { code: 200 };
      } else {
        throw new Error('Failed to confirm phone number');
      }
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const getCondoTypes = createAsyncThunk(
  'auth-user/getCondoTypes',
  async () => {
    try {
      let loginResponse = await condofy.get(`/v1/condominium-types`);

      return loginResponse;
    } catch (error: any) {
      console.log({ error });
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth-user/changePassword',
  async ({
    newPassword,
    oldPassword,
  }: {
    newPassword: string;
    oldPassword: string;
  }) => {
    try {
      const token = getToken();
      if (!token) {
        throw new Error('Token is missing');
      }

      let loginResponse = await condofyPrivate(token).put(
        `/accounts/change-password`,
        {
          currentPassword: oldPassword,
          newPassword: newPassword,
          confirmNewPassword: newPassword,
        }
      );

      return loginResponse;
    } catch (error: any) {
      console.error({ error });
    }
  }
);

export const finishCondominium = createAsyncThunk(
  'auth-user/finishCondominium',
  async (body: condoCreateBodyNew) => {
    try {
      const token = getToken();
      if (!token) {
        throw new Error('Token is missing');
      }
      let loginResponse = await condofyPrivate(token).post(
        `/v1/plans/sign-up-starter`,
        body
      );

      return loginResponse;
    } catch (error: any) {
      console.error({ error });
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth-user/forgotPassword',
  async (email: string) => {
    try {
      let loginResponse = await condofy.post(`/accounts/forgot-password`, {
        email: email,
        isStarterFlow: true,
        resetPasswordUrl:
          'https://auth-staging.condofy.vercel.app/password/starter/reset',
      });

      return loginResponse;
    } catch (error: any) {
      console.error({ error });
    }
  }
);

export default authSlice.reducer;
