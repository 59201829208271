import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Box, Typography } from '@mui/material';
import colors from '../../global/theme/colors';
import { CheckedIcon } from '../localIcons';

interface Props {
    value: boolean;
    label: string;
    onChange: Function;
    color?: string;
    fontFamily?: string;
    fontWeight?: string | number;
}


export const BpCheckbox: React.FC<Props> = ({ value, label, onChange, color, fontFamily, fontWeight, ...props }) => {
    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Checkbox
                value={value}
                checked={value}
                checkedIcon={<CheckedIcon />}
                onChange={(e) => {
                    if (value === true) {
                        onChange(false)
                    } else {
                        onChange(true)
                    }
                }}
                sx={{
                    color: colors.dark.D200,
                    '&:hover': { bgcolor: 'transparent' },
                    '& .PrivateSwitchBase-input': {
                        backgroundColor: 'red'
                    }
                }}
                disableRipple
                inputProps={{ 'aria-label': 'Checkbox demo' }}
                {...props}
            />
            <Typography sx={{
                color: color ? color : colors.dark.D600,
                fontFamily: fontFamily ? fontFamily : 'Inter, sans-serif',
                fontSize: 14,
                fontWeight: fontWeight ? fontWeight : 400
            }}>{label}</Typography>
        </Box>
    );
}