import { Box, FormControl, InputLabel, MenuItem, Modal } from '@mui/material';
import { Select } from '../../@condofy-components/select';
import { Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBox, LinkBlue, MainContainer, ModalContainerFlexible, SubContainer } from '../../global/styles/style';
import colors from '../../global/theme/colors';
import { TextField } from '../../@condofy-components/textField';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { auth } from '../../redux/authSlice';
import { onBlocksChange, onFloorsChange, onTypeIdChange, onUnitsPerFloorChange, user } from '../../redux/userSlice';
import { useEffect, useState } from 'react';
import { NextButton } from '../../@condofy-components/nextButton';

const min = 1;
const max = 100;
const minDecimal = 1;
const maxOfUnits = 50;

export const CondominiumConfig = () => {
  const { condoTypes } = useAppSelector(auth);
  const { typeId, blocks, floors, unitsPerFloor } = useAppSelector(user);
  const dispatch = useAppDispatch();
  const [modalUnitsLimitOver, setModalUnitsLimitOver] = useState(false);
  const [isNumOfUnitsCorrect, setIsNumOfUnitsCorrect] = useState(true);

  const checkBlocksNumber = () => {
    if (blocks as number > max) { dispatch(onBlocksChange(max)) };
    if (blocks as number < min) { dispatch(onBlocksChange(min)) };
  }

  const checkFloorsNumber = () => {
    if (floors as number > max) { dispatch(onFloorsChange(max)) };
    if (floors as number < min) { dispatch(onFloorsChange(min)) };
  }

  const checkUnitsPerFloorNumber = () => {
    if (unitsPerFloor as number > max) { dispatch(onUnitsPerFloorChange(max)) };
    if (unitsPerFloor as number < minDecimal) { dispatch(onUnitsPerFloorChange(min)) };
  }

  useEffect(() => {
    let test = unitsMoreThanLimit(maxOfUnits);
    if (test) {
      setModalUnitsLimitOver(true);
      setIsNumOfUnitsCorrect(false);
    } else {
      setIsNumOfUnitsCorrect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const unitsMoreThanLimit = (limit: number) => {
    if (!blocks || !floors || !unitsPerFloor) {
      return false;
    }
    let firstUnitNumber = '1'

    let tempUnits: any[] = [];
    for (let block = 1; block <= blocks; block++) {
      for (let floor = 1; floor <= floors; floor++) {
        for (let unit = 1; unit <= unitsPerFloor; unit++) {
          let unitNumber;
          if (floor === 1 && unit === 1) {
            console.log(14)
            unitNumber = firstUnitNumber
          } else if (block >= 1 && floor > 1 && unit === 1) {
            let lastUnitNumberSplited = tempUnits[tempUnits.length - 1].number.split('')
            lastUnitNumberSplited[lastUnitNumberSplited.length - 1] = String(firstUnitNumber)[String(firstUnitNumber).length - 1]
            lastUnitNumberSplited = String(Number(lastUnitNumberSplited.join('')) + 100)
            unitNumber = lastUnitNumberSplited;
          }
          else {
            unitNumber = String(Number(tempUnits[tempUnits.length - 1].number) + 1);
          }

          tempUnits.push({
            block: `${block}`,
            number: `${unitNumber}`,
            type: "1"
          });
        }
      }
    }
    return tempUnits.length > limit
  }

  return (
    <MainContainer data-testid="condominuim-config">
      <SubContainer>
        <Header style={{ margin: 0 }}>Configuração do Condomínio</Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          Precisamos conhecer a estrutura do seu condomínio, conta ai!
        </SubHeader>
        <FlexibleBox>
          <Label style={{ marginTop: '16px' }}>
            Qual é o tipo do seu condomínio?
          </Label>
          <FormControl sx={{ marginTop: '16px', width: '100%' }}>
            {typeId === null && (
              <InputLabel sx={{ color: colors.dark.D200 }}>Selecione</InputLabel>
            )}
            <Select data-testid="select-condominuim-type" onChange={(e: any) => dispatch(onTypeIdChange(e.target.value))} value={typeId}>
              {condoTypes.map((type) => (
                <MenuItem key={type.id} value={type.id} data-testid="condominium-type-item">
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Label style={{ marginTop: '16px' }}>Quantos blocos?</Label>
          <TextField
            data-testid="number-of-blocks"
            type='number'
            onBlur={() => {
              let test = unitsMoreThanLimit(maxOfUnits);
              if (test) {
                setModalUnitsLimitOver(true);
                setIsNumOfUnitsCorrect(false);
              } else {
                setIsNumOfUnitsCorrect(true);
                checkBlocksNumber()
              }
            }}
            placeholder="Número de blocos"
            sx={{ marginTop: '16px' }}
            value={blocks}
            onChange={(e: any) => dispatch(onBlocksChange(e.target.value))}
          />
          <Label style={{ marginTop: '16px' }}>Quantos andares?</Label>
          <TextField
            data-testid="number-of-floors"
            type='number'
            placeholder="Número de andares"
            sx={{ marginTop: '16px' }}
            value={floors}
            onBlur={() => {
              let test = unitsMoreThanLimit(maxOfUnits);
              if (test) {
                setModalUnitsLimitOver(true);
                setIsNumOfUnitsCorrect(false);
              } else {
                setIsNumOfUnitsCorrect(true);
                checkFloorsNumber()
              }
            }}
            onChange={(e: any) => dispatch(onFloorsChange(e.target.value))
            }
          />
          <Label style={{ marginTop: '16px' }}>
            Unidades por andar
          </Label>
          <TextField
            data-testid="units-per-floor"
            type='number'
            onBlur={() => {
              let test = unitsMoreThanLimit(maxOfUnits);
              if (test) {
                setModalUnitsLimitOver(true);
                setIsNumOfUnitsCorrect(false);
              } else {
                setIsNumOfUnitsCorrect(true);
                checkUnitsPerFloorNumber()
              }
            }}
            placeholder="Unidades por andar"
            sx={{ marginTop: '16px' }}
            value={unitsPerFloor}
            onChange={(e: any) => dispatch(onUnitsPerFloorChange(e.target.value))}
          />
        </FlexibleBox>
        <Modal open={modalUnitsLimitOver} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ModalContainerFlexible>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <Header>
                O limite do o starter é de {maxOfUnits} unidades
              </Header>
              <SubHeader style={{ margin: '0px 0px 18px 0px', textAlign: 'center' }}>
                Para mais unidades contate nosso time comercial <LinkBlue href='mailto:querosercliente@condofy.com.br' target='_blank' rel='noopener noreferrer'>querosercliente@condofy.com.br</LinkBlue>
              </SubHeader>
            </Box>
            <Box sx={{ display: 'flex', width: '90%', justifyContent: 'center' }}>
              <NextButton
                disappear={false}
                customText='Fechar'
                disabled={false}
                onClick={() => {
                  setModalUnitsLimitOver(false)
                }}
              />
            </Box>
          </ModalContainerFlexible>
        </Modal>
      </SubContainer>
      <NextAndPrevComponents disableNext={!blocks || !floors || !typeId || !isNumOfUnitsCorrect} />
    </MainContainer>
  );
};
