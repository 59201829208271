import { TextField, TextFieldPassword } from '../../@condofy-components/textField';
import { ForgotPassword, Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBox, MainContainer, ModalContainerFlexible, SubContainer } from '../../global/styles/style';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { auth, forgotPassword, loginUser, logoutAuth, onEmailOrCpfChange } from '../../redux/authSlice';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { onActiveAlertError, onActiveAlertSuccess } from '../../redux/alertSlice';
import { logoutUser, onCpfChange } from '../../redux/userSlice';
import { adaptiveMask } from '../../utils/masks';
import { isValidEmail } from '../../utils';
import { goesToStep } from '../../redux/stepperSlice';
import { Box, Modal } from '@mui/material';
import { NextButton } from '../../@condofy-components/nextButton';
import { PreviouslyButton } from '../../@condofy-components/previouslyButton';
import styled from 'styled-components';

const FlexModalButton = styled(Box)`
  display: flex;
  width: 90%; 
  justify-content: center;
  @media (max-width: 500px){
    width: 100%;
  }
`

export const Login = () => {
  const dispatch = useAppDispatch();
  const { emailOrCpf } = useAppSelector(auth);
  const [password, setPassword] = useState('')
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [forgotPasswordField, setForgotPasswordField] = useState(isValidEmail(emailOrCpf) ? emailOrCpf : '');


  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        console.log(buttonElement)
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const onSubmit = () => {
    if (!emailOrCpf || !password) {
      return
    }
    login();
  }

  const login = async () => {
    dispatch(loginUser({ email: emailOrCpf, password: password }))
      .then((response: any) => {
        if (response.payload) {
          if (response.payload.profile) {
            dispatch(
              onCpfChange(
                response.payload.profile.socialSecurityNumber === ''
                  ?
                  ''
                  :
                  response.payload.profile.socialSecurityNumber
              )
            )
          }
          dispatch(goesToStep(5));
        } else {
          dispatch(onActiveAlertError("Email ou senha inválido(a)."))
        }
        return response
      }).catch((error) => {
        console.log(error)
        throw new Error('Login error')
      })
  }

  const sendRecoveryPassword = () => {
    dispatch(forgotPassword(forgotPasswordField))
      .then(() => {
        setForgotPasswordModal(false)
        dispatch(onActiveAlertSuccess("Email enviado caso o email seja válido"))
      })
  }

  const onChangeEmailChecking = (e: any) => {
    let value = e.target.value;
    dispatch(onEmailOrCpfChange(adaptiveMask(value)));
  }

  return (
    <MainContainer key={2}>
      <SubContainer>
        <Header style={{ margin: 0 }}>
          Vimos que já um usuário da Condofy. Legal!
        </Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          Para prosseguir, entre com suas credenciais
        </SubHeader>
        <FlexibleBox>
          <Label>Seu email ou CPF</Label>
          <TextField
            id='emailOrCpf'
            disabled={true}
            placeholder="Seu email ou CPF"
            sx={{ marginTop: '16px' }}
            value={emailOrCpf}
            onChange={(e: any) => onChangeEmailChecking(e)}
          />
          <Label style={{ marginTop: '16px' }}>Senha</Label>
          <TextFieldPassword
            placeholder="Senha"
            id="password"
            sx={{ marginTop: '16px' }}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <ForgotPassword onClick={() => setForgotPasswordModal(true)}>Esqueci minha senha</ForgotPassword>
        </FlexibleBox>
        <Modal open={forgotPasswordModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ModalContainerFlexible>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <Header>
                Esqueceu sua senha?
              </Header>
              <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
                Você receberá um link de redefinição no seu email.
              </SubHeader>
              <TextField
                placeholder="Seu Email"
                id="forgotPasswordField"
                sx={{ marginTop: '16px' }}
                value={forgotPasswordField}
                onChange={(e: any) => setForgotPasswordField(e.target.value)}
              />
            </Box>
            <FlexModalButton>
              <PreviouslyButton
                disappear={false}
                onClick={() => setForgotPasswordModal(false)}
                customText='Cancelar'
              />
              <NextButton
                disappear={false}
                customText='Enviar'
                disabled={!forgotPasswordField}
                onClick={sendRecoveryPassword}
              />
            </FlexModalButton>
          </ModalContainerFlexible>
        </Modal>
        {/* <Modal open={emailAlreadyExist} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ModalContainerFlexible>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <Header>
                Já existe uma conta usando esse email
              </Header>
              <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
                Deseja usar as suas credenciais?
              </SubHeader>
            </Box>
            <FlexModalButton>
              <PreviouslyButton
                disappear={false}
                onClick={() => {
                  setEmailAlreadyExist(false);
                  dispatch(onEmailOrCpfChange(''))
                }
                }
                customText='Não'
              />
              <NextButton
                disappear={false}
                customText='Sim'
                onClick={() => {
                  dispatch(logoutAuth());
                  dispatch(logoutUser());
                  setPassword('');
                }}
              />
            </FlexModalButton>
          </ModalContainerFlexible>
        </Modal> */}
      </SubContainer>
      <NextAndPrevComponents
        onNext={onSubmit}
        onReturn={() => {
          dispatch(logoutAuth({}));
          dispatch(logoutUser());
          setPassword('');
          dispatch(goesToStep(0))
        }}
        disableNext={!password || !emailOrCpf}
      />
    </MainContainer>
  );
};
