import styled from 'styled-components';
import colors from '../../global/theme/colors';
import background from '../../img/background-stepper-hor.png';

export const MainContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background-color: ${colors.light.L100};
  /* @media (max-width: 670px) {
    height: 98dvh;
  } */

  @media (max-width: 925px) {
    height: 78dvh;
  }

  *::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #f6f8fb;
  }

  *::-webkit-scrollbar-track:hover {
    background-color: #f6f8fb;
  }

  *::-webkit-scrollbar-track:active {
    background-color: #f6f8fb;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #bfcbe1;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #b2bed2;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: #b2bed2;
  }

  /* @media (max-width: 768px) {
    flex-direction: row;
    height: auto;
  } */
`;

export const ContentContainer = styled.div`
  width: 78%;
  height: 100%;
  max-height: 100%;

  @media (max-width: 925px) {
    width: 100%;
  }
`;

export const MobileLogContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  height: 20dvh;
  display: none;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  @media (max-width: 925px) {
    display: flex;
  }
  img {
    height: 40px;
    width: 156px;
  }
`;

export const MobileLog = styled.div`
  width: 100%;
  height: 80%;
  background-color: red;
  display: flex;
  justify-content: center;
  background-image: url(${background});
  background-position: center;
  align-items: center;
  img {
    height: 40px;
    width: 156px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 10%;
  padding: 0 45px;
`;
