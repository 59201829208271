import styled from "styled-components";
import colors from "../../global/theme/colors";

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 312px;
`;

export const ImageStyled = styled.img`
  height: 200px;
  @media only screen and (min-height: 690px) and (max-height: 720px) {
    height: 170px;
  }
  @media only screen and (min-height: 0px) and (max-height: 690px) {
    height: 140px;
  }
  @media (max-width: 570px){
    height: 120px;
  }
`

export const Heading3 = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: ${colors.darkBlue.DB600};
  
`;

export const Message = styled.span`
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 10px;
  text-align: center;
`;

export const CondofyLogo = styled.img`
  width: 230px;
  display: block;
  margin-top: 5%;
  @media only screen and (min-height: 690px) and (max-height: 720px) {
    width: 200px;
  }
  @media only screen and (min-height: 0px) and (max-height: 690px) {
    width: 180px;
  }
  @media (max-width: 925px){
    width: 140px;
    margin-top: 0%;
  }
`;

export const ErrorMessage = styled(Message)`
  color: #ff3838;
`;

export const PrimaryMessage = styled(Message)`
  font-size: 14px;
  line-height: 19px;
  color: ${colors.darkBlue.DB600};
`;

export const RessendMessage = styled(Message)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${colors.darkBlue.DB600};

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 300;
  height: 90%;
  min-height: fit-content;
  flex: 1;
  padding: 4% 8% 0 9%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  @media (max-width: 570px){
    height: 100%
  }
`;