import styled from '@emotion/styled';
import Logo from '../../img/Logo.svg';
import emailImage from '../../img/illustracao_email.svg';
import { css } from '@emotion/react';
import colors from '../../global/theme/colors';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useEffect } from 'react';
import { auth, resendEmailToken, testEmailWithoutLoading } from '../../redux/authSlice';
import { onActiveAlertError, onActiveAlertSuccess } from '../../redux/alertSlice';
import { MainContainer } from '../../global/styles/style';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { goesToStep } from '../../redux/stepperSlice';

export const link = css`
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;

  text-decoration-line: underline;
`;

const Link = styled.a`
  ${link}
  cursor: pointer;
  margin: 10px 5px;
`;

export const CondofyLogo = styled.img`
  width: 230px;
  display: block;
  margin-top: 5%;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: 312px;
`;

export const Heading3 = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: ${colors.darkBlue.DB600};
`;

const Message = styled.span`
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 10px;
  text-align: center;
`;

const PrimaryMessage = styled(Message)`
  font-size: 14px;
  line-height: 19px;
  color: ${colors.darkBlue.DB600};
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 300;
  justify-content: space-between;
  height: 90%;
  align-items: center;
  min-height: fit-content;
  flex: 1;
  padding: 4% 8% 0 9%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
`;

export const EmailConfirmation = () => {
  const dispatch = useAppDispatch();
  const { email } = useAppSelector(auth);

  const resendToken = () => {
    dispatch(resendEmailToken({ email }))
      .then(() => {
        dispatch(onActiveAlertSuccess("Email reenviado com sucesso."))
      }).catch(() => {
        dispatch(onActiveAlertError("Falha ao reenviar o email, tente novamente."))
      })
  }

  const checkEmail = () => {
    dispatch(testEmailWithoutLoading({ email }))
      .then((response: any) => {
        console.log(response);
        if (!response.payload || !response.payload.data) {
          return
        }
        const treatedResponse = response.payload.data.data

        console.log(treatedResponse)
        if (treatedResponse.emailConfirmed) {
          if (!treatedResponse.phoneNumberConfirmed) {
            dispatch(goesToStep(3));
          } else {
            dispatch(goesToStep(4));
          }
        }
      })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checkEmail()
    }, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer>
      <SubContainer>
        <CondofyLogo src={Logo} alt="Condofy Logo" />
        <FormGroup>
          <Heading3>Confirme seu email</Heading3>
          <img alt="ilustação email" src={emailImage} />
          <PrimaryMessage>
            <span>
              Por favor, verifique seu email e clique no link enviado para
              confirmar sua conta. Não recebeu o email?
            </span>
            <Link onClick={() => resendToken()}>Re-enviar link</Link>
          </PrimaryMessage>
        </FormGroup>
        <div></div>
      </SubContainer>
      <NextAndPrevComponents hideNext disableNext isLogout/>
    </MainContainer>
  );
};
