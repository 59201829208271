import styled, { css } from 'styled-components';
import { Select as SelectMui } from '@mui/material';
import colors from '../../global/theme/colors';
import { useEffect } from 'react';

const fieldPadding = css`
  .MuiOutlinedInput {
    &-input {
      height: 25px;
      padding: 12px !important;
    }
  }
  .MuiInputLabel-outlined {
    margin-top: -6px;

    &.MuiInputLabel-shrink {
      margin-top: 0;
    }
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px !important;
  }
`;

const fieldBorder = css`
  .MuiOutlinedInput {
    &-input {
      z-index: 1;
    }
    &-notchedOutline {
      border-color: #dfe6df;
      background-color: #fafcfa;
      z-index: 0;
    }
  }
  &:hover {
    .MuiInputBase-root:not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border-color: #c9cbd0;
      }
    }
  }
`;

const fieldLabelColor = css`
  .MuiFormLabel-root {
    white-space: nowrap;
  }
`;

const fieldTypography = css`
  .MuiFormLabel-root,
  .MuiInputBase-input {
    font-family: 'inter', sans-serif !important;
    font-size: 14px;
  }
  .MuiInputBase-root {
    border-color: #c9cbd0;
    min-height: 40px;
    line-height: 1.75;
  }
  .MuiInputBase-root:focus {
    border-color: #c9cbd0;
  }
  .MuiInputBase-input::placeholder {
    color: ${colors.dark.D200};
    opacity: 1;
  }
`;

const fieldAdornmentStyle = css`
  .MuiInputAdornment-root {
    z-index: 3;
  }
  .MuiSelect-icon {
    z-index: 3;
  }
`;


const StyledSelectMui = styled(SelectMui)`
  ${fieldPadding}
  ${fieldBorder}
  ${fieldLabelColor}
  ${fieldTypography}
  ${fieldAdornmentStyle}
  width: 100%;

  ${({ error }) =>
    error &&
    css`
      .MuiFormLabel-root {
        color: red; // Change the label color to red when error is true
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: red; // Change the border color to red when error is true
      }
    `}
`;

export const Select = ({ children, value, ...args }: any) => {
  useEffect(() => {
    console.log(value)
  }, [value])
  if (value === null) {
    return (<StyledSelectMui {...args} label={<p>Selecione</p>}>
      {children}
    </StyledSelectMui>)
  } else {
    return (<StyledSelectMui {...args} value={value}>
      {children}
    </StyledSelectMui>)
  }
};
