import { Provider } from 'react-redux';
import Index from './pages/index';
import { persistor, store } from './redux/store';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CheckoutComponent } from './components/payData';
import { ReturnStripe } from './pages/returnStripe/returnStripe';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route index element={<Index />} />
            <Route path='/checkout/:clientSecret' element={<CheckoutComponent />} />
            <Route path='/finished/:session_id' element={<ReturnStripe />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>);
}

export default App;
