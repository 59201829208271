/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import colors from '../../global/theme/colors';

const Reset = styled.div`
  border: none;
  outline: none;
  appearance: none;
`;

export const FlexInline = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
`;

export const body1 = css`
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
`;

export const Base = styled(Reset)`
  display: inline-flex;
  flex: 1 1 auto;

  background: #ffffff;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);

  ${body1}
  color: ${colors.darkBlue.DB600};

  margin: 8px;
  padding: 12px 16px;

  box-sizing: border-box;

  &::placeholder {
    color: ${colors.light.L400};
  }

  @media (max-width: 400px){
    padding: 12px 10px;
  }
`;

export const Input = styled(Base.withComponent('input'))`
  width: 100%;

  border-radius: 6px;
`;

const identity = (x: any) => x;

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

const SquareInput = styled(Input)`
  border: 1px solid ${colors.light.L600};
  text-align: center;
  width: 45px;
  height: 45px;
  margin-top: 12px;
  margin-bottom: 15px;
  &,
  ::placeholder {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }
  @media (max-width: 570px){
    width: 34px;
    height: 34px;
    font-size: 14px;
    &,
  ::placeholder {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
  }
`;

export const Code = ({
  fields = 6,
  autoFocus = true,
  disabled = false,
  required = false,
  onChange: emitChangeEvent = identity,
  onComplete: emitCompleteEvent = identity,
  values,
}: any) => {
  const [innerValues, setInnerValues] = useState(
    values || Array(fields).fill('')
  );
  const [autoFocusIndex, setAutoFocusIndex] = useState(0);
  const inputRefs = useRef([]);

  if (inputRefs.current.length !== innerValues.length) {
    inputRefs.current = Array(innerValues.length)
      .fill('')
      .map((value, index) => inputRefs.current[index] || React.createRef());
  }

  useEffect(() => {
    const newValues = [];
    const focusIndex = innerValues.length >= fields ? 0 : innerValues.length;

    for (let index = 0; index < fields; index++) {
      newValues.push(innerValues[index] || '');
    }

    setAutoFocusIndex(focusIndex);
    setInnerValues(newValues);
  }, []);

  useEffect(() => {
    const newValues = innerValues.join('');
    emitChangeEvent(newValues);
    if (newValues.length >= fields) emitCompleteEvent(newValues);
  }, [innerValues]);

  const handleChange = (event: any) => {
    const inputIndex = parseInt(event.target.dataset.id);
    const inputValue = event.target.value.replace(/[^\d]/gi, '');
    const values = Object.assign([], innerValues);
    let next: any;

    if ((!inputValue && inputValue !== 0) || !event.target.validity.valid)
      return;

    if (inputValue.length > 1) {
      let nextIndex = inputValue.length + inputIndex - 1;

      if (nextIndex >= fields) {
        nextIndex = fields - 1;
      }

      next = inputRefs.current[nextIndex];

      inputValue.split('').forEach((item: any, index: number) => {
        const cursor = inputIndex + index;
        if (cursor < fields) values[cursor] = item;
      });

      setInnerValues(values);
    } else {
      next = inputRefs.current[inputIndex + 1];
      values[inputIndex] = inputValue;
      setInnerValues(values);
    }

    if (next) {
      next.current.focus();
      next.current.select();
    }
  };

  const handleKeyDown = (event: any) => {
    const index = parseInt(event.target.dataset.id);
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev: any = inputRefs.current[prevIndex];
    const next: any = inputRefs.current[nextIndex];
    let newValues;
    switch (event.keyCode) {
      case KEY_CODE.backspace:
        event.preventDefault();
        newValues = [...innerValues];

        if (innerValues[index]) {
          newValues[index] = '';
          setInnerValues(newValues);
        } else if (prev) {
          newValues[prevIndex] = '';
          prev.current.focus();
          setInnerValues(newValues);
        }

        break;

      case KEY_CODE.left:
        event.preventDefault();
        if (prev) prev.current.focus();
        break;

      case KEY_CODE.right:
        event.preventDefault();
        if (next) next.current.focus();
        break;

      case KEY_CODE.up:
      case KEY_CODE.down:
        event.preventDefault();
        break;

      default:
        break;
    }
  };

  return (
    <FlexInline data-testid="verify-code">
      {innerValues.map((value: any, index: number) => {
        return (
          <SquareInput
            data-testid='square-input'
            type="tel"
            placeholder="0"
            pattern="[0-9]*"
            autoFocus={autoFocus && index === autoFocusIndex}
            key={index}
            data-id={index}
            value={value}
            ref={inputRefs.current[index]}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={(event: any) => event.target.select(event)}
            disabled={disabled}
            required={required}
          />
        );
      })}
    </FlexInline>
  );
};
