import styled from 'styled-components';
import { Header, SubHeader } from '../../@condofy-components/typoFonts';
import { MainContainer, SubContainer } from '../../global/styles/style';
import colors from '../../global/theme/colors';
import { Typography } from '@mui/material';
import { BpCheckbox } from '../../@condofy-components/checkbox';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { onAgreeChange, user } from '../../redux/userSlice';
import { auth, getCondoTypes } from '../../redux/authSlice';
import { useEffect } from 'react';
import { cpfMask } from '../../utils/masks';

const Title = styled.p`
  color: ${colors.dark.D700};
  font-weight: 600;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  margin: 0;
  margin-bottom: 8px;
`

const DividerLine = styled.hr`
  border: 1px solid ${colors.light.L300}; 
  width: 100%;
  margin: 2.85vh 0;
  @media only screen and (min-height: 720px) and (max-height: 884px){
    margin: 2vh 0;
  };
  @media only screen and (min-height: 690px) and (max-height: 720px) {
    margin: 1.5vh 0;
  };
  @media only screen and (min-height: 0px) and (max-height: 690px) {
    margin: 1.2vh 0;
  };
`

export const Review = () => {
  const { cnpj, legalName, city, number, state, street, district, tradeName, cpf, role, goal, agreeUseTerms, doNotKnowCnpj, customCondoName } = useAppSelector(user);
  const { userName } = useAppSelector(auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  useEffect(() => {
    dispatch(getCondoTypes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainContainer data-testid='review'>
      <SubContainer>
        <Header style={{ margin: 0 }}>Revisão e Termos</Header>
        <SubHeader style={{ margin: '0px 0px 28px 0px' }}>
          Precisamos saber um pouco mais sobre você, vamos lá?
        </SubHeader>
        <Title>Condomínio</Title>
        <Typography
          className='inter'
          sx={{
            color: colors.dark.D400,
            fontSize: 14,
            marginBottom: '2px'
          }}>
          {doNotKnowCnpj ? "CNPJ não informado" : cnpj}
        </Typography>
        <Typography
          sx={{
            color: colors.dark.D400,
            fontSize: 14,
            fontFamily: 'Manrope, sans-serif',
            fontWeight: 500
          }}>
          {doNotKnowCnpj ? customCondoName : tradeName ? tradeName : legalName ? legalName : 'Sem nome'} - {street}, {number}, {district}, {city}, {state}</Typography>
        <DividerLine />
        <Title>Seu Nome</Title>
        <Typography className='inter'
          sx={{
            color: colors.dark.D400,
            fontSize: 14,
            fontFamily: 'Manrope, sans-serif',
            fontWeight: 500
          }}>
          {userName}
        </Typography>
        <DividerLine />
        <Title>Seu CPF</Title>
        <Typography
          className='inter'
          sx={{
            color: colors.dark.D400,
            fontSize: 14,
            marginBottom: '2px'
          }}>
          {cpfMask(cpf)}
        </Typography>
        <DividerLine />
        <Title>Papel no condomínio</Title>
        <Typography
          className='inter'
          sx={{
            color: colors.dark.D400,
            fontSize: 14,
            marginBottom: '2px'
          }}>
          {
            role === 3 ? 'Proprietário'
              :
              role === 4 ? 'Inquilino'
                :
                'Imobiliária'
          }
        </Typography>
        <DividerLine />
        <Title>Objetivo ao contratar a Condofy</Title>
        <Typography
          className='inter'
          sx={{
            color: colors.dark.D400,
            fontSize: 14,
            marginBottom: '2px'
          }}>
          {goal}
        </Typography>
        <DividerLine />
        <BpCheckbox data-testid="check-authorization" onChange={(e: any) => {
          console.log(e)
          dispatch(onAgreeChange(e))
        }} value={agreeUseTerms} label={`Declaro que tenho autorização para gerenciar o condomínio  de CNPJ: ${cnpj}`} />
      </SubContainer>
      <NextAndPrevComponents disableNext={!agreeUseTerms} />
    </MainContainer>
  );
};
