import { Box, Typography } from '@mui/material';
import colors from '../../global/theme/colors';
import { ArrowLeftStepIcon, ArrowLeftStepIconDisabled } from '../localIcons';
import { FC } from 'react';
import styled from 'styled-components';

interface PropsBox {
  disabled: boolean | undefined;
  marginLeft: number | string | undefined;
  unique?: boolean;
}

const FlexBox = styled(Box) <PropsBox>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.light.L100};
  width: 188px;
  height: 52px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  border-radius: 0.5;
  margin-left: ${props => props.marginLeft ? props.marginLeft : 5};
  hover: {
    background-color: ${props => props.disabled ? colors.light.L100 : colors.light.L200};
  };
  user-select: none;
  @media (max-width: 926px){
    width: ${props => props.unique ? '100%' : '48%'};
  }
`

interface Props {
  disabled?: boolean;
  onClick: () => void;
  disappear: boolean | undefined;
  customText?: string;
  marginLeft?: number | string;
  id?: string;
}

export const PreviouslyButton: FC<Props> = ({ disabled, onClick, disappear, customText, marginLeft, id }) => {

  if (disappear) {
    return (
      <Box></Box>
    )
  }

  return (
    <FlexBox
      id={id}
      marginLeft={marginLeft}
      disabled={disabled}
      onClick={() => disabled ? {} : onClick()}
    >
      {disabled ? <ArrowLeftStepIconDisabled /> : <ArrowLeftStepIcon />}
      <Typography
        sx={{
          color: disabled ? colors.brandBlue.BB100 : colors.brandBlue.BB700,
          fontFamily: 'Poppins, sans-serif',
          fontSize: 18,
          fontWeight: 500,
          marginLeft: 2,
        }}
      >
        {customText ? customText : 'Voltar'}
      </Typography>
    </FlexBox>
  );
};
