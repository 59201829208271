/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useState, useEffect } from 'react';
import Logo from '../../img/Logo.svg';
import emailImage from '../../img/illustracao_email.svg';
import colors from '../../global/theme/colors';
import { Code } from '../../@condofy-components/codeInput';
import {
  ConfirmButton,
  ResendButton,
} from '../../@condofy-components/confirmButton';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { auth, confirmPhone, resendPhoneToken, userId, userLoadingAuth } from '../../redux/authSlice';
import { onActiveAlertError, onActiveAlertSuccess } from '../../redux/alertSlice';
import { CondofyLogo, ErrorMessage, FormGroup, Heading3, SubContainer, PrimaryMessage, RessendMessage, ImageStyled } from './styles';
import { MainContainer } from '../../global/styles/style';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { advanceStep } from '../../redux/stepperSlice';

const PhoneNumberConfirmationPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [complete, setComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(60);
  const dispatch = useAppDispatch();
  const { email, phone } = useAppSelector(auth);
  const id = useAppSelector(userId);
  const authLoading = useAppSelector(userLoadingAuth);

  useEffect(() => {
    if (errorMessage) setErrorMessage('');
    if (code.length < 6) setComplete(false);
  }, [code]);

  useEffect(() => {
    let intervalId: any;
    if (buttonDisabled) {
      intervalId = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          setButtonDisabled(false);
          clearInterval(intervalId);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [buttonDisabled, timeLeft]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('confirmButton');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // const handleSuccess = () => {
  //   setLoading(false);
  //   window.location.href = 'http://www.google.com';
  // };

  const sendConfirmationCode = () => {
    console.log({ phone, id, code })
    dispatch(confirmPhone({ phone, id: id as string, token: code }))
      .then((response) => {
        console.log({ response })
        if (response.payload) {
          dispatch(onActiveAlertSuccess('Telefone confirmado com sucesso.'));
          dispatch(advanceStep());
        } else {
          dispatch(onActiveAlertError('Erro ao confirmar o código, confirme e tente novamente.'))
        }
      }).catch(() => (dispatch(onActiveAlertError('Erro ao confirmar o código, confirme e tente novamente.'))))
  };

  const resendSms = () => {
    setButtonDisabled(true);
    setTimeLeft(60);
    dispatch(resendPhoneToken({ email }))
      .then(() => { dispatch(onActiveAlertSuccess('Código reenviado com sucesso.')) })
      .catch(() => (dispatch(onActiveAlertError('Erro ao reenviar o código, tente novamente.'))))
  };

  return (
    <MainContainer>
      <SubContainer>
        <CondofyLogo src={Logo} alt="Condofy Logo"/>
        <FormGroup>
          <Heading3>Confirme seu celular</Heading3>
          <ImageStyled alt="ilustação email" src={emailImage} />
          <PrimaryMessage>
            <span>
              Por favor, verifique seu celular e digite o código de 6 digitos que
              enviamos para confirmar sua conta. Não recebeu o código?
            </span>
          </PrimaryMessage>
          <RessendMessage>
            <ResendButton onClick={() => resendSms()} disabled={buttonDisabled}>
              Re-enviar Código
            </ResendButton>
            {buttonDisabled ? (
              <div className="container">
                <AccessTimeIcon sx={{ color: colors.darkBlue.DB600 }} />
                <span>Tempo restante: {timeLeft} segundos</span>
              </div>
            ) : (
              <span />
            )}
          </RessendMessage>
          <Code
            value={code}
            onChange={setCode}
            onComplete={() => setComplete(true)}
          />
          <ConfirmButton
            id="confirmButton"
            loading={loading || authLoading}
            onClick={() => sendConfirmationCode()}
            disabled={!code || !complete || loading || authLoading}
          >
            Confirmar
          </ConfirmButton>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </FormGroup>
      </SubContainer>
      <NextAndPrevComponents hideNext disableNext isLogout/>
    </MainContainer>
  );
};

export default PhoneNumberConfirmationPage;
