import {
  InfosContainer,
  LogoContainer,
  MainContainer,
  StepsContainer,
} from './style';
import logo from '../../img/logo-white.png';
import { EmailOutlinedIcon } from '../../@condofy-components/localIcons';
import VerticalLinearStepper from '../../@condofy-components/verticalLinearStepper';

export const Stepper = () => {
  return (
    <MainContainer>
      <LogoContainer>
        <img src={logo} alt="condofy logo" />
      </LogoContainer>
      <StepsContainer>
        <VerticalLinearStepper />
      </StepsContainer>
      <InfosContainer>
        <p>&copy; Condofy 2024</p>
        <a href="mailto:contato@condofy.com.br">
          <div id="emailContainer">
            <EmailOutlinedIcon />
            <p>contato@condofy.com.br</p>
          </div>
        </a>
      </InfosContainer>
    </MainContainer>
  );
};
