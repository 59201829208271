/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormControl, InputLabel, MenuItem, Typography } from '@mui/material';
import { Select } from '../../@condofy-components/select';
import { Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBox, MainContainer, SubContainer } from '../../global/styles/style';
import colors from '../../global/theme/colors';
import { TextField } from '../../@condofy-components/textField';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  onBlockIndicationBooleanChange,
  onBlockIndicationChange,
  onFirstUnitNumberChange,
  onUnitNomenclatureChange,
  onUnitPatternChange,
  onUnitsChange,
  user
} from '../../redux/userSlice';
import { setLoading } from '../../redux/authSlice';
import { useEffect, useState } from 'react';
import { advanceStep } from '../../redux/stepperSlice';

const minHundred = 101;
const minDecimal = 11;
const max = 1000;

export const UnitsIdentify = () => {
  const {
    unitPattern,
    firstUnitNumber,
    blockIndicationBoolean,
    blockIndication,
    unitsPerFloor,
    unitNomenclature,
    blocks,
    floors,
    typeId
  } = useAppSelector(user);
  const dispatch = useAppDispatch();
  const [unitType, setUnitType] = useState(0);

  useEffect(() => {
    if (blockIndicationBoolean === 0) {
      dispatch(onBlockIndicationChange(null))
    }
  }, [blockIndicationBoolean])

  useEffect(() => {
    if (blockIndication !== 2) {
      dispatch(onUnitNomenclatureChange(''))
    }
  }, [blockIndication])

  useEffect(() => {
    if (unitPattern !== null) {
      dispatch(onFirstUnitNumberChange(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitPattern])

  useEffect(() => {
    if (firstUnitNumber === null) {
      checkFirstUnitNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstUnitNumber])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const generateUnits = () => {
    dispatch(setLoading(true));
    if (!blocks || !floors || !unitsPerFloor || !firstUnitNumber) {
      return;
    }

    let tempUnits: any[] = [];
    for (let block = 1; block <= blocks; block++) {
      for (let floor = 1; floor <= floors; floor++) {
        for (let unit = 1; unit <= unitsPerFloor; unit++) {
          let unitNumber;
          if (floor === 1 && unit === 1) {
            console.log(14)
            unitNumber = firstUnitNumber
          } else if (block >= 1 && floor > 1 && unit === 1) {
            console.log(17)
            if (unitPattern === 1) {
              let lastUnitNumberSplited = tempUnits[tempUnits.length - 1].number.split('')
              lastUnitNumberSplited[lastUnitNumberSplited.length - 1] = String(firstUnitNumber)[String(firstUnitNumber).length - 1]
              lastUnitNumberSplited = String(Number(lastUnitNumberSplited.join('')) + 100)
              unitNumber = lastUnitNumberSplited;
            } else {
              let lastUnitNumberSplited = tempUnits[tempUnits.length - 1].number.split('')
              lastUnitNumberSplited[lastUnitNumberSplited.length - 1] = String(firstUnitNumber)[String(firstUnitNumber).length - 1]
              lastUnitNumberSplited = String(Number(lastUnitNumberSplited.join('')) + 10)
              unitNumber = lastUnitNumberSplited;
            }
          }
          else {
            unitNumber = String(Number(tempUnits[tempUnits.length - 1].number) + 1);
          }

          tempUnits.push({
            block: `${block}`,
            number: `${unitNumber}`,
            typeId: unitType
          });
        }
      }
    }

    console.log(tempUnits);
    dispatch(setLoading(false));
    dispatch(onUnitsChange(tempUnits));
    dispatch(advanceStep());
  }


  const checkFirstUnitNumber = () => {
    if (firstUnitNumber as number > max) { dispatch(onFirstUnitNumberChange(max)) };
    if (firstUnitNumber as number < (unitPattern === 0 ? minDecimal : minHundred)) { dispatch(onFirstUnitNumberChange(unitPattern === 0 ? minDecimal : minHundred)) };
  }

  return (
    <MainContainer data-testid="units-identify">
      <SubContainer>
        <Header style={{ margin: 0 }}>Identificação das unidades</Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          Como é sequência de numeração das unidades
        </SubHeader>
        <FlexibleBox>
          <Label style={{ marginTop: '16px' }}>
            Qual é o padrão utilizado?
          </Label>
          <FormControl sx={{ marginTop: '16px', width: '100%' }}>
            {unitPattern === null && (
              <InputLabel sx={{ color: colors.dark.D200 }}>Selecione</InputLabel>
            )}
            <Select
              data-testid="select-standard-of-units"
              onChange={(e: any) => dispatch(onUnitPatternChange(e.target.value))}
              value={unitPattern}>
              <MenuItem key={0} value={0}>
                <Typography component="span" sx={{ color: colors.dark.D500, marginRight: 1 }}>
                  Decimal
                </Typography>
                <Typography component="span" sx={{ color: colors.light.L600 }}>
                  (ex: 01)
                </Typography>
              </MenuItem>
              <MenuItem key={1} value={1}>
                <Typography component="span" sx={{ color: colors.dark.D500, marginRight: 1 }}>
                  Centésimo
                </Typography>
                <Typography component="span" sx={{ color: colors.light.L600 }}>
                  (ex: 101)
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <Label style={{ marginTop: '16px' }}>Inicia em qual numeração?</Label>
          <TextField
            data-testid="start-with-number"
            type='number'
            disabled={unitPattern === null}
            placeholder={unitPattern === null ? '0' : unitPattern === 0 ? '11' : '101'}
            onBlur={() => checkFirstUnitNumber()}
            sx={{ marginTop: '16px' }}
            value={firstUnitNumber}
            onChange={(e: any) => dispatch(onFirstUnitNumberChange(e.target.value))}
            inputProps={{
              min: unitPattern === 0 ? minDecimal : minHundred,
              max: max,
            }}
          />
          <Label style={{ marginTop: '16px' }}>Existe indicação de bloco?</Label>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <FormControl sx={{ marginTop: '16px', width: '48.5%' }}>
              {blockIndicationBoolean === null && (
                <InputLabel sx={{ color: colors.dark.D200 }}>Selecione</InputLabel>
              )}
              <Select
                data-testid="select-block-indication"
                onChange={(e: any) => dispatch(onBlockIndicationBooleanChange(e.target.value))}
                value={blockIndicationBoolean}>
                <MenuItem key={1} value={1}>
                  Sim
                </MenuItem>
                <MenuItem key={0} value={0}>
                  Não
                </MenuItem>
              </Select>
            </FormControl>
            {blockIndicationBoolean ? (<FormControl sx={{ marginTop: '16px', width: '48.5%' }}>
              {blockIndication === null && (
                <InputLabel sx={{ color: colors.dark.D200 }}>Selecione</InputLabel>
              )}
              <Select
                data-testid="select-block-indication-type"
                onChange={(e: any) => dispatch(onBlockIndicationChange(e.target.value))}
                value={blockIndication}>
                <MenuItem key={0} value={0}>
                  Torre
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Bloco
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Outro
                </MenuItem>
              </Select>
            </FormControl>)
              :
              null}
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {blockIndication === 2 && blockIndicationBoolean !== 0 && (
              <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <Label style={{ marginTop: '16px', width: '100%' }}>
                  Outra Indicação
                </Label>
                <TextField
                  placeholder="Indicação personalizada"
                  sx={{ marginTop: '16px', width: '100%' }}
                  value={unitNomenclature}
                  onChange={(e: any) => dispatch(onUnitNomenclatureChange(e.target.value))}
                />
              </Box>)}
          </Box>
          <Label style={{ marginTop: '16px' }}>
            Qual é o tipo de unidade?
          </Label>
            <FormControl sx={{ marginTop: '16px', width: '100%' }}>
                <Select
                  data-testid="select-type-of-units"
                  onChange={(e: any) => setUnitType(e.target.value)}
                  value={unitType}>
                  <MenuItem key={0} value={0}>
                    <Typography component="span" sx={{ color: colors.dark.D500, marginRight: 1 }}>
                      Selecione
                    </Typography>
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    <Typography component="span" sx={{ color: colors.dark.D500, marginRight: 1 }}>
                      Apartamento
                    </Typography>
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    <Typography component="span" sx={{ color: colors.dark.D500, marginRight: 1 }}>
                      Casa
                    </Typography>
                  </MenuItem>
                  <MenuItem key={99} value={99}>
                    <Typography component="span" sx={{ color: colors.dark.D500, marginRight: 1 }}>
                      Outro
                    </Typography>
                  </MenuItem>
                </Select>
              </FormControl>
        </FlexibleBox>
      </SubContainer>
      <NextAndPrevComponents
        onNext={generateUnits}
        disableNext={
          blockIndication === 2 ? (unitNomenclature.length <= 0 ? true : false) : false ||
            unitPattern === null ||
            !firstUnitNumber ||
            blockIndicationBoolean === null ||
            blockIndicationBoolean === 1 ? (blockIndication !== null ? false : true) : false ||
          !unitsPerFloor || unitType === 0
        } />
    </MainContainer>
  );
};
