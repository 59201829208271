import { Box, Button, Modal, Typography } from '@mui/material';
import { Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBoxConfigUnits, MainContainer, SubContainer } from '../../global/styles/style';
import colors from '../../global/theme/colors';
import { UnitsList } from '../../@condofy-components/unitsList';
import AddIcon from '@mui/icons-material/Add';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { onUnitAdd, user } from '../../redux/userSlice';
import { useEffect, useState } from 'react';
import { TextField } from '../../@condofy-components/textField';
import { NextButton } from '../../@condofy-components/nextButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { onActiveAlertError } from '../../redux/alertSlice';
import styled from 'styled-components';

const minHundred = 101;
const minDecimal = 11;

const FlexBox = styled(Box)`
  background-color: ${colors.light.L100};
  width: 20%;
  height: 32%;
  border-radius: 1px;
  padding: 0.5% 0.5% 2% 0.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 670px){
    width: 75%;
    height: 35%;
    padding: 2% 0.5% 6% 0.5%;
  }
`

export const UnitsConfig = () => {
  const { units } = useAppSelector(user);
  const dispatch = useAppDispatch();
  const [addModal, setAddModal] = useState(false);
  const { unitPattern } = useAppSelector(user);
  const [newName, setNewName] = useState<string | null>('');
  const [unitType, setUnitType] = useState<number>(0);

  const unitNameExists = async (newName: string) => {
    return units.some(unit => unit.number === newName && unit.block === '');
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButtonModal');
        console.log(buttonElement)
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const checkNewName = () => {
    if (Number(newName) < (unitPattern === 0 ? minDecimal : minHundred)) { setNewName(unitPattern === 0 ? String(minDecimal) : String(minHundred)) };
  }

  const handleAddUnit = async (block: string, number: string, typeId: number) => {
    const test = await unitNameExists(number)
    if (test) {
      dispatch(onActiveAlertError("Já existe uma unidade com esse nome, por favor escolha um outro nome."))
      setAddModal(false);
      return;
    }
    dispatch(onUnitAdd({ block, number, typeId }));
    setAddModal(false);
  }

  return (
    <MainContainer data-testid="units-config">
      <SubContainer>
        <FlexibleBoxConfigUnits>
          <Header style={{ margin: 0 }}>Configuração das unidades</Header>
          <SubHeader style={{ margin: '0px 0px 48px 0px' }}>
            Informe e revise a numeração
          </SubHeader>
          <Typography color='#747d85' sx={{
            fontFamily: 'Open Sans, sans-serif',
            fontSize: 12,
            marginBottom: 1,
          }}>Edite ou exclua a(s) unidade(s) conforme necessário.</Typography>
          <UnitsList onSave={() => { }} list={units} />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 180,
            marginTop: 3,
            userSelect: 'none'
          }} onClick={() => setAddModal(true)}>
            <AddIcon sx={{ color: colors.brandBlue.BB700, cursor: 'pointer' }} />
            <Typography color={colors.brandBlue.BB700} sx={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              fontSize: 16,
              cursor: 'pointer'
            }}>Adicionar unidade</Typography>
          </Box>
        </FlexibleBoxConfigUnits>
      </SubContainer>
      <NextAndPrevComponents disableNext={!units.length} />
      <Modal open={addModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <FlexBox>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
            <Button onClick={() => setAddModal(false)}>
              <HighlightOffIcon sx={{ color: colors.dark.D200, fontSize: 30 }} />
            </Button>
          </Box>
          <Label>Qual o número da unidade</Label>
          <TextField
            onBlur={() => checkNewName()}
            placeholder={'Número da unidade'}
            mobileWidth={'70%'}
            normalWidth={'188px'}
            value={newName}
            onChange={(e: any) => setNewName(e.target.value)}
          />
          <NextButton
            id='nextButtonModal'
            onClick={() => {
              setNewName('');
              handleAddUnit('', String(newName), unitType);
            }}
            disabled={!newName}
            customText='Adicionar'
          />
        </FlexBox>
      </Modal>
    </MainContainer>
  );
};
