import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { FC, useRef, useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { TextFieldUnit } from "../textField";
import { unitType } from "../../types/types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { onUnitDelete, onUnitEdit, user } from "../../redux/userSlice";
import { onActiveAlertError } from "../../redux/alertSlice";

interface Props {
    list: Array<unitType>;
    onSave: (id: string, newName: string) => void;
}

export const UnitsList: FC<Props> = ({ list, onSave }) => {
    const [editingBlock, setEditingBlock] = useState<string | null>(null);
    const [editingId, setEditingId] = useState<string | null>(null);
    const [editedNumber, setEditedNumber] = useState<string>('');
    const textFieldRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const { units, blockIndicationBoolean, blockIndication, unitNomenclature } = useAppSelector(user);

    const unitNameExists = async () => {
        console.log(units.filter(unit => unit.number === editedNumber && unit.block === editingBlock))
        return units.some(unit => unit.number === editedNumber && unit.block === editingBlock);
    };

    const handleDelete = (number: string, block: string) => {
        dispatch(onUnitDelete({ number, block }));
    };

    const handleEdit = (block: string, name: string, id: number) => {
        setEditingBlock(block);
        setEditedNumber(name);
        setEditingId(String(id))
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedNumber(event.target.value);
    };

    const handleSave = async (oldName: string, oldBlock: string) => {
        const test = await unitNameExists();
        if (test) {
            dispatch(onActiveAlertError("Já existe uma unidade com esse nome, por favor escolha um outro nome."))
            setEditingBlock(null);
            setEditingId(null);
            setEditedNumber('');
            return false;
        }
        console.log({ block: editingBlock, name: editedNumber })
        dispatch(onUnitEdit({ block: oldBlock, number: oldName, newNumber: editedNumber }));
        setEditingBlock(null);
        setEditingId(null);
        setEditedNumber('');
        return true;
    };

    return (
        <Box sx={{ width: '100%', height: 309, overflow: 'auto' }}>
            <Grid container spacing={window.innerWidth < 600 ? 0 : 1}>
                {Array.isArray(list) ? list.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                border: '1px solid #e9edf4',
                                borderRadius: 2,
                                height: 48,
                                padding: '0 5% 0 8%',
                                textAlign: 'center'
                            }}>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                                {editingId === String(index) ? (
                                    <TextFieldUnit
                                        inputRef={textFieldRef}
                                        value={editedNumber}
                                        onChange={handleChange}
                                        autoFocus
                                        onBlur={() => handleSave(item.number, item.block)}
                                        fullWidth
                                        sx={{ height: '50%' }}
                                    />
                                ) : (
                                    <Tooltip title="Click para editar">
                                        <Typography
                                            onClick={() => handleEdit(item.block, item.number, index)}
                                            sx={{ cursor: 'pointer', fontFamily: 'Open Sans, sans-serif', color: '#212836' }}
                                        >
                                            {item.number} {item.block ? '-' : ''} {blockIndicationBoolean && item.block ? (blockIndication === 0 ? 'T' : blockIndication === 1 ? 'B' : unitNomenclature[0]) : ''}{item.block ? item.block : ''}
                                        </Typography>
                                    </Tooltip>
                                )}
                            </Box>
                            <Box>
                                <IconButton aria-label="delete" onClick={() => handleDelete(item.number, item.block)}>
                                    <DeleteOutlineOutlinedIcon sx={{ color: '#DE350B' }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                )) : null}
            </Grid>
        </Box>
    )
}