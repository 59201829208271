import { LinearProgress } from "@mui/material";
import { MobileBarContainer } from "./style";
import { useAppSelector } from "../../hooks";
import { stepper } from "../../redux/stepperSlice";


export const MobileStepper = () => {
    const { steps } = useAppSelector(stepper);
    return (
        <MobileBarContainer>
            {steps.length > 0 && steps.map((step, index) => (
                <LinearProgress
                    value={step.status === 2 ? 100 : step.status === 1 ? 50 : -1}
                    variant="determinate"
                    sx={[
                        { width: '13.5%', height: '6px', backgroundColor: '#E8ECF5' },
                        index === 0
                            ?
                            { borderTopLeftRadius: 2, borderBottomLeftRadius: 2 }
                            :
                            index === 6
                                ?
                                { borderTopRightRadius: 2, borderBottomRightRadius: 2 }
                                :
                                null
                    ]} />
            ))}
        </MobileBarContainer>
    )
};
