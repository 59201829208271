import { Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBox, MainContainer, SubContainer } from '../../global/styles/style';
import { useEffect, useState } from 'react';
import { TextFieldPassword } from '../../@condofy-components/textField';
import { Box } from '@mui/material';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { auth, changePassword, loginUser } from '../../redux/authSlice';
import { advanceStep, goesToStep } from '../../redux/stepperSlice';
import { onActiveAlertError } from '../../redux/alertSlice';
import { getToken } from '../../utils';

export const SetPassword = () => {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [secret, setSecret] = useState(true);
    const [errorMessageFirst, setErrorMessageFirst] = useState('');
    const [errorMessageSecond, setErrorMessageSecond] = useState('');
    const { email, token } = useAppSelector(auth);
    const dispatch = useAppDispatch();

    function isValidPassword(password: string): boolean {
        console.log(password);
        const hasSymbol = /[@#$%^&*(),.?":{}|<>]/.test(password);
        const hasUpperCase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        console.log({ result: hasSymbol && hasUpperCase && hasNumber })
        return hasSymbol && hasUpperCase && hasNumber;
    }

    useEffect(() => {
        if (!token) {
            dispatch(loginUser({ email: email, password: email }))
        }
    }, [])

    useEffect(() => {
        if (!isValidPassword(password) && password.length > 0) {
            if (!errorMessageFirst) {
                setErrorMessageFirst('Senha precisa conter no minimo um simbolo, uma letra maiuscula e um numero.');
            }
        } else {
            setErrorMessageFirst('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password])

    useEffect(() => {
        if (password !== passwordConfirmation && passwordConfirmation.length > 0) {
            if (!errorMessageSecond) {
                setErrorMessageSecond('As duas senhas devem ser iguais.')
            }
        }
        else {
            setErrorMessageSecond('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordConfirmation])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                let buttonElement = document.getElementById('nextButton');
                if (buttonElement) {
                    buttonElement.click();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const updatePassword = () => {
        if (!password || !passwordConfirmation || (passwordConfirmation.length > 0 &&
            password !== passwordConfirmation)) {
            return
        }
        dispatch(changePassword({ newPassword: password, oldPassword: email }))
            .then((response: any) => {
                console.log(response);
                if (response.payload && response.payload.status && response.payload.status === 204) {
                    dispatch(goesToStep(14));
                } else {
                    dispatch(onActiveAlertError('Erro ao atualizar a senha, tente novamente.'));
                }
                console.log(response);
            }).catch((error) => {
                dispatch(onActiveAlertError('Erro ao atualizar a senha, tente novamente.'));
                console.log(error);
            })
    }


    return (
        <MainContainer>
            <SubContainer>
                <Header style={{ margin: 0 }}>Dados Pessoais</Header>
                <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
                    Digite uma senha forte para proteger sua conta e continuar.
                </SubHeader>
                <FlexibleBox>
                    <Label style={{ marginTop: '16px' }}>Sua Senha</Label>
                    <TextFieldPassword
                        thirdSafe={secret}
                        helperText={errorMessageFirst}
                        thirdSafeChange={setSecret}
                        placeholder="Senha"
                        sx={{ marginTop: '16px' }}
                        value={password}
                        onChange={(e: any) => setPassword(e.target.value)}
                    />
                    <Label style={{ marginTop: '16px' }}>Confirmar Senha</Label>
                    <TextFieldPassword
                        thirdSafe={secret}
                        helperText={errorMessageSecond}
                        thirdSafeChange={setSecret}
                        error={
                            password.length > 0 &&
                            passwordConfirmation.length > 0 &&
                            password !== passwordConfirmation
                        }
                        placeholder="Confirmar Senha"
                        sx={{ marginTop: '16px' }}
                        value={passwordConfirmation}
                        onChange={(e: any) => setPasswordConfirmation(e.target.value)}
                    />
                </FlexibleBox>
            </SubContainer>
            <NextAndPrevComponents
                onNext={updatePassword}
                disableNext={!password || !passwordConfirmation || (passwordConfirmation.length > 0 &&
                    password !== passwordConfirmation)}
            />
        </MainContainer>
    );
};
