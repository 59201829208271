import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

// Define the initial state
const initialState = {
  currentStep: 0,
  currentComponentIndex: 0,
  steps: [
    {
      label: 'Dados Pessoais',
      status: 1,
    },
    {
      label: 'Dados do Condomínio',
      status: 0,
    },
    {
      label: 'Revisão e Termos de Responsabilidade',
      status: 0,
    },
    {
      label: 'Configuação do Condomínio',
      status: 0,
    },
    {
      label: 'Configuação das Unidades',
      status: 0,
    },
    {
      label: 'Dados de Pagamento',
      status: 0,
    },
    {
      label: 'Concluído',
      status: 0,
    },
  ],
};

// Define the slice
const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    setStep0: (state) => {
      state.steps[0].status = 1;
      state.steps[1].status = 0;
      state.steps[2].status = 0;
      state.steps[3].status = 0;
      state.steps[4].status = 0;
      state.steps[5].status = 0;
      state.steps[6].status = 0;
    },
    setStep1: (state) => {
      state.steps[0].status = 2;
      state.steps[1].status = 1;
      state.steps[2].status = 0;
      state.steps[3].status = 0;
      state.steps[4].status = 0;
      state.steps[5].status = 0;
      state.steps[6].status = 0;
    },
    setStep2: (state) => {
      state.steps[0].status = 2;
      state.steps[1].status = 2;
      state.steps[2].status = 1;
      state.steps[3].status = 0;
      state.steps[4].status = 0;
      state.steps[5].status = 0;
      state.steps[6].status = 0;
    },
    setStep3: (state) => {
      state.steps[0].status = 2;
      state.steps[1].status = 2;
      state.steps[2].status = 2;
      state.steps[3].status = 1;
      state.steps[4].status = 0;
      state.steps[5].status = 0;
      state.steps[6].status = 0;
    },
    setStep4: (state) => {
      state.steps[0].status = 2;
      state.steps[1].status = 2;
      state.steps[2].status = 2;
      state.steps[3].status = 2;
      state.steps[4].status = 1;
      state.steps[5].status = 0;
      state.steps[6].status = 0;
    },
    setStep5: (state) => {
      state.steps[0].status = 2;
      state.steps[1].status = 2;
      state.steps[2].status = 2;
      state.steps[3].status = 2;
      state.steps[4].status = 2;
      state.steps[5].status = 1;
      state.steps[6].status = 0;
    },
    setStep6: (state) => {
      state.steps[0].status = 2;
      state.steps[1].status = 2;
      state.steps[2].status = 2;
      state.steps[3].status = 2;
      state.steps[4].status = 2;
      state.steps[5].status = 2;
      state.steps[6].status = 1;
    },
    finishSteps: (state) => {
      state.steps[0].status = 2;
      state.steps[1].status = 2;
      state.steps[2].status = 2;
      state.steps[3].status = 2;
      state.steps[4].status = 2;
      state.steps[5].status = 2;
      state.steps[6].status = 2;
    },
    goesToStep: (state, action) => {
      state.currentStep = action.payload;
    },
    advanceStep: state => {
      if (state.currentStep < 15) {
        state.currentStep += 1;
      }
    },
    concludeSteps: state => {
      state.currentStep = 14;
    },
    returnStep: state => {
      if (state.currentStep > 0) {
        state.currentStep -= 1;
      }
    },
    advanceComponentIndex: state => {
      state.currentComponentIndex += 1;
    },
    returnComponentIndex: state => {
      if (state.currentComponentIndex > 0) {
        state.currentComponentIndex -= 1;
      }
    },
  },
});

// Export actions
export const {
  goesToStep,
  advanceStep,
  concludeSteps,
  returnStep,
  advanceComponentIndex,
  returnComponentIndex,
  setStep0,
  setStep1,
  setStep2,
  setStep3,
  setStep4,
  setStep5,
  setStep6,
  finishSteps
} = stepperSlice.actions;

export const stepper = (state: RootState) => state.stepper;

// Export reducer
export default stepperSlice.reducer;
