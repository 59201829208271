import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '../../global/theme/colors';
import { blue } from '@mui/material/colors';
import {
  CompleteStepIcon,
  CurrentStepIcon,
  InactiveStepIcon,
} from '../localIcons';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { finishSteps, setStep0, setStep1, setStep2, setStep3, setStep4, setStep5, setStep6, stepper } from '../../redux/stepperSlice';
import styled from 'styled-components';

const LabelStyled = styled(Typography)`
  font-size: 17;
  @media only screen and (min-width: 925px) and (max-width: 1310px) {
    font-size: 15;
  }
`

export default function VerticalLinearStepper() {
  const { currentStep, steps } = useAppSelector(stepper);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentStep <= 4) {
      dispatch(setStep0());
    } else if (currentStep === 5) {
      dispatch(setStep1());
    } else if (currentStep === 6) {
      dispatch(setStep2());
    } else if (currentStep === 7) {
      dispatch(setStep3());
    } else if (currentStep >= 8 && currentStep <= 10) {
      dispatch(setStep4());
    } else if (currentStep >= 11 && currentStep <= 12) {
      dispatch(setStep5());
    } else if (currentStep === 13) {
      dispatch(setStep6());
    } else {
      dispatch(finishSteps());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])


  return (
    <Box
      sx={{
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        userSelect: 'none'
      }}
    >
      {steps.length && steps.map((step, index) => (
        <Box
          key={step.label}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {step.status === 2 ? (
              <CompleteStepIcon
                color={blue}
                style={{ fontSize: '24px', marginRight: 1 }}
              />
            ) : step.status === 1 ? (
              <CurrentStepIcon
                color={blue}
                style={{ fontSize: '24px', marginRight: 1 }}
              />
            ) : (
              <InactiveStepIcon
                color={blue}
                style={{ fontSize: '24px', marginRight: 1 }}
              />
            )}
            <LabelStyled sx={{
              fontFamily: 'poppins, sans-serif',
              fontWeight: '500',
              color: colors.light.L100,
              marginLeft: 1,
            }}>
              {step.label}
            </LabelStyled>
          </Box>
          {index < steps.length - 1 && (
            <Box
              sx={{
                width: '2px',
                height: '28px',
                backgroundColor: colors.light.L100,
                borderRadius: 5,
                margin: '4px 0 4px 14px',
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
}
