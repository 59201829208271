import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
 spin: {
    animation: '$spin 2s linear infinite',
 },
 '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
 },
});

const AnimatedIcon = () => {
 const classes = useStyles();
 return <RefreshIcon className={classes.spin} />;
};

export default AnimatedIcon;
