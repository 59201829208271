import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { Box } from '@mui/material';
import { Header, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBox, MainContainer, SubContainer } from '../../global/styles/style';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { condofy } from '../../api/base';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { user } from '../../redux/userSlice';
import { ConfirmButton } from '../../@condofy-components/confirmButton';
import colors from '../../global/theme/colors';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import PaymentIcon from '@mui/icons-material/Payment';
import { auth } from '../../redux/authSlice';
import { advanceStep, goesToStep } from '../../redux/stepperSlice';


const key = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(key as string);

const EmbeddedCheckoutButton = () => {
  const baseUrl = window.location.origin;
  const { paymentSubscriptionId } = useAppSelector(user);
  const navigation = useNavigate();

  const handleCheckoutClick = async () => {
    const result = await condofy.post(`/stripe/sessions?redirectUrl=${baseUrl}/finished/session_id={CHECKOUT_SESSION_ID}`);
    navigation(`/checkout/${result.data.clientSecret}`);
  };


  return (
    <Box id="checkout" className="my-4">
      {paymentSubscriptionId.length === 0
        ?
        (<ConfirmButton
          data-testid="button-confirm-payment-data"
          className="btn"
          disabled={paymentSubscriptionId.length > 0}
          onClick={handleCheckoutClick}
          icon={<PaymentIcon fontSize='large' sx={{ color: colors.light.L100 }} />}
          width={360}
        >
          Cadastrar meio de pagamento
        </ConfirmButton>)
        :
        (<ConfirmButton
          className="btn"
          disabled={true}
          onClick={handleCheckoutClick}
          icon={<CheckCircleOutlineRoundedIcon sx={{ color: colors.light.L100 }} />}
          width={340}
        >
          Assinatura configurada
        </ConfirmButton>)
      }
    </Box>
  );
}

export const PayData: React.FC = () => {
  const { paymentSubscriptionId } = useAppSelector(user);
  const { isTemporary } = useAppSelector(auth);
  const dispatch = useAppDispatch();

  return (
    <MainContainer data-testid="pay-data">
      <SubContainer>
        <Header style={{ margin: 0 }}>Dados de pagamento</Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          {paymentSubscriptionId.length === 0
            ?
            'Para utilizar o plano Starter é necessário informar um meio de pagamento'
            :
            'Você já cadastrou seu meio de pagamento para o plano Starter'
          }
        </SubHeader>
        { }
        <FlexibleBox>
          <EmbeddedCheckoutButton />
        </FlexibleBox>
      </SubContainer>
      <NextAndPrevComponents
        disableNext={!paymentSubscriptionId}
        onNext={() => isTemporary ? dispatch(advanceStep()) : dispatch(goesToStep(14))}
      />
    </MainContainer>
  );
};

export function CheckoutComponent() {
  const { clientSecret } = useParams();

  return (
    <EmbeddedCheckoutProvider
      data-testid="checkout"
      stripe={stripePromise}
      options={{ clientSecret: clientSecret }}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}
