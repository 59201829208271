import { Box } from '@mui/material';
import { Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBox, MainContainer, SubContainer } from '../../global/styles/style';
import { useEffect, useState } from 'react';
import { AutocompleteTextField } from '../../@condofy-components/textField';
import { BpCheckbox } from '../../@condofy-components/checkbox';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { onUserUnitChange, setUserUnityToAdm, unsetUserUnityToAdm, user } from '../../redux/userSlice';

export const ChooseUnity = () => {
  const [isBuildingManager, setIsBuildingManager] = useState(false);
  const { units, userUnit } = useAppSelector(user);
  const dispatch = useAppDispatch();
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    if (!isBuildingManager) {
      dispatch(unsetUserUnityToAdm());
    } else {
      dispatch(setUserUnityToAdm());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuildingManager])

  useEffect(() => {
    console.log(userUnit)
    if (userUnit && userUnit.number === "ADM") {
      setIsBuildingManager(true);
    } else {
      setIsBuildingManager(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainContainer data-testid="choose-unity">
      <SubContainer>
        <Header style={{ margin: 0 }}>Qual a sua unidade?</Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          Confirme a sua unidade e a sua situação neste condomínio
        </SubHeader>
        <FlexibleBox>
          <Label style={{ marginTop: '16px' }}>Qual a sua unidade?</Label>
          <AutocompleteTextField
            data-testid="unity"
            valueParent={userUnit}
            disabled={isBuildingManager}
            placeholder='Qual a sua unidade?'
            sx={{ marginTop: '16px', marginBottom: '10px' }}
            options={units}
            onChange={(event: any, newValue: any) => {
              console.log({ event, newValue })
              dispatch(onUserUnitChange(newValue))
            }}
          />
          <BpCheckbox
            color='rgba(0, 25, 71, 0.734)'
            onChange={() => setIsBuildingManager(!isBuildingManager)}
            value={isBuildingManager}
            label='Sou síndico profissional e não moro no condomínio'
            fontFamily='Poppins, sans-serif'
            fontWeight={500}
          />
        </FlexibleBox>
      </SubContainer>
      <NextAndPrevComponents disableNext={!userUnit ? (isBuildingManager ? false : true) : false} />
    </MainContainer>
  );
};
