import styled from 'styled-components';
import colors from '../../global/theme/colors';
import { Grid } from '@mui/material';

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  img {
    height: 40px;
    width: 166.03px;
  }
`;

export const GridFlex = styled(Grid)`
  display: flex;
  align-items: center;
  padding-top: 16px;
  width: 100%;
  @media (min-width: 1550px) and (max-width: 1700px) {
    width: 110% !important;
  }
  @media (min-width: 1415px) and (max-width: 1550px) {
    width: 120% !important;
  }
  @media only screen and (min-width: 1320px) and (max-width: 1415px) {
    width: 130% !important;
  }
  @media only screen and (min-width: 1220px) and (max-width: 1320px) {
    width: 140% !important;
  }
  @media only screen and (min-width: 1170px) and (max-width: 1220px) {
    width: 150% !important;
  }
  @media only screen and (min-width: 1060px) and (max-width: 1170px) {
    width: 160% !important;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1060px) {
    width: 170% !important;
  }
  @media only screen and (min-width: 935px) and (max-width: 1000px) {
    width: 180% !important;
  }
  @media only screen and (min-width: 925px) and (max-width: 935px) {
    width: 190% !important;
  }
  /* @media only screen and (max-width: 400px) {
    width: 150% !important;
  } */
`;

export const StepsContainer = styled.div`
  width: 100%;
`;

export const InfosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  color: ${colors.light.L100};
  img {
    height: 20px;
    width: 20px;
  }
  #emailContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }
  a {
    width: 42%;
    text-decoration: none;
    color: ${colors.light.L100};
  }
`;
