import { Box, Button, Typography } from '@mui/material';
import React, { FC } from 'react';
import colors from '../../global/theme/colors';
import styled from 'styled-components';

interface PropsBox {
  disabled: boolean | undefined;
  single?: boolean | undefined;
}

const FlexBox = styled(Box) <PropsBox>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.disabled
    ? colors.brandBlue.BB100
    : colors.brandBlue.BB700};
  width: 160px;
  height: 25px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  border-radius: 0.5;
  &:hover {
    background-color: ${props => props.disabled
    ? colors.brandBlue.BB100
    : colors.brandBlue.BB600
  }};
  user-select: none;
  @media (max-width: 925px){
    width: ${props => props.single ? '100%' : '92%'};
  }
`

interface Props {
  disabled?: boolean;
  onClick: () => void;
  title: string;
  style?: React.CSSProperties;
  testid?: string
}

export const ActionButton: FC<Props> = ({ title, disabled, onClick, style, testid }) => {
  return (
    <FlexBox
      sx={style}
      disabled={disabled}
    >
      <Button data-testid={testid} onClick={() => disabled ? {} : onClick()}>
        <Typography
          sx={{
            color: colors.light.L100,
            fontFamily: 'Poppins, sans-serif',
            fontSize: 13,
            marginTop: '3px',
            fontWeight: 400,
          }}
        >
          {title}
        </Typography>
      </Button>
    </FlexBox>
  );
};
