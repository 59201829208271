import { ButtonsContainer } from './style';
import { NextButton } from '../nextButton';
import { PreviouslyButton } from '../previouslyButton';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FC } from 'react';
import { logoutUser } from '../../redux/userSlice';
import { logoutAuth } from '../../redux/authSlice';
import { advanceStep, goesToStep, returnStep, stepper } from '../../redux/stepperSlice';

interface Props {
  isLogout?: boolean;
  onNext?: Function;
  returnText?: string;
  onReturn?: Function;
  hideReturn?: boolean;
  disableNext: boolean;
  hideNext?: boolean;
  disableReturn?: boolean;
  generalDisable?: boolean;
  custumableNextId?: string;
}


export const NextAndPrevComponents: FC<Props> = ({
  onNext,
  hideReturn,
  disableNext,
  disableReturn,
  generalDisable,
  onReturn,
  returnText,
  hideNext,
  isLogout,
  custumableNextId
}) => {
  const { currentStep } = useAppSelector(stepper);
  const dispatch = useAppDispatch();

  const handleNextButtonClick = () => {
    if (!disableNext) {
      if (onNext) {
        onNext()
      } else {
        if (currentStep >= 13) {
          return;
        } else if (currentStep === 12) {
          dispatch(advanceStep());
        } else {
          dispatch(advanceStep());
        }
      }
    }
  };

  return (
    <ButtonsContainer>
      <PreviouslyButton
        id="returnButton"
        customText={isLogout ? 'Logout' : returnText}
        disappear={currentStep >= 13 || hideReturn}
        disabled={disableReturn || generalDisable}
        onClick={() => {
          if (isLogout) {
            dispatch(logoutAuth({}));
            dispatch(logoutUser());
            dispatch(goesToStep(0));
            return;
          }
          if (onReturn) {
            onReturn()
          } else {
            dispatch(returnStep());
          }
        }}
      />
      <NextButton
        id={custumableNextId ? custumableNextId : "nextButton"}

        disappear={hideNext}
        lastStep={currentStep >= 13}
        disabled={generalDisable || disableNext}
        onClick={handleNextButtonClick}
        single={currentStep >= 13 || hideReturn}
      />
    </ButtonsContainer>
  );
};
