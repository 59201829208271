import { AboutCondominium } from '../../components/aboutCondominium';
import { CheckEmail } from '../../components/checkEmail';
import { ChooseUnity } from '../../components/chooseUnity';
import { CondominiumConfig } from '../../components/condominiumConfig';
import { EmailConfirmation } from '../../components/emailConfirmation';
import { PayData } from '../../components/payData';
import { PersonalData } from '../../components/personalData';
import PhoneNumberConfirmationPage from '../../components/phoneNumber';
import { Register } from '../../components/register';
import { Review } from '../../components/review';
import { SetPassword } from '../../components/setPassword';
import { Stepper } from '../../components/stepper';
import { UnitsConfig } from '../../components/unitsConfig';
import { UnitsIdentify } from '../../components/unitsIdentify';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ContentContainer, MainContainer, MobileLog, MobileLogContainer } from './style';
import { IsBuilding } from '../../components/isBuilding';
import { Box, CircularProgress, Modal, Stack } from '@mui/material';
import { resetState, userLoadingAuth } from '../../redux/authSlice';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { alert, onDisableAlertError, onDisableAlertSuccess } from '../../redux/alertSlice';
import { useEffect } from 'react';
import { stepper } from '../../redux/stepperSlice';
import styled from 'styled-components';
import logo from '../../img/logo-white.png';
import { MobileStepper } from '../../components/mobileStepper';
import { Login } from '../../components/loginScreen';

const FlexibleStack = styled(Stack)`
  width: 30%;
  padding-top: 2%;
  padding-right: 2%;
  position: absolute;
  height: 90dvh;
  right: 0;
  @media (max-width: 600px){
    width: 90dvw;
    height: 10dvh;
    padding-top: 5%;
  }
`

const steps = [
  CheckEmail, //0
  Login, //1
  Register,  //2
  EmailConfirmation, //3
  PhoneNumberConfirmationPage, //4
  PersonalData, //5
  AboutCondominium, //6
  Review, //7
  CondominiumConfig, //8
  UnitsIdentify, //9
  UnitsConfig, //10
  ChooseUnity, //11
  PayData, //12
  SetPassword, //13
  IsBuilding //14
];

const Index = () => {
  const authLoading = useAppSelector(userLoadingAuth);
  const { currentStep } = useAppSelector(stepper);
  const CurrentStepComponent = currentStep === 15 ? steps[14] : steps[currentStep];
  const { activeAlertError, activeAlertSuccess, alertErrorMessage, alertSuccessMessage } = useAppSelector(alert);
  const dispatch: any = useAppDispatch();

  useEffect(() => {
    if (activeAlertSuccess) {
      setTimeout(() => dispatch(onDisableAlertSuccess({})), 4000)
    }
    if (activeAlertError) {
      setTimeout(() => dispatch(onDisableAlertError({})), 4000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAlertError, activeAlertSuccess])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      console.log('beforeunload event fired');
      dispatch(resetState());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <MainContainer>
      <Stepper />
      <ContentContainer>
        <MobileLogContainer>
          <MobileLog>
            <img src={logo} alt="condofy logo" />
          </MobileLog>
          <MobileStepper />
        </MobileLogContainer>
        {CurrentStepComponent && <CurrentStepComponent />}
      </ContentContainer>
      <Modal open={authLoading} hideBackdrop>
        <Box sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#ffffff92',
        }}>
          <CircularProgress />
        </Box>
      </Modal>
      <FlexibleStack spacing={2}>
        {activeAlertSuccess && (
          <Alert
            severity="success"
            onClose={() => dispatch(onDisableAlertSuccess({}))}
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <AlertTitle>Sucesso</AlertTitle>
            {alertSuccessMessage}
          </Alert>
        )}
        {activeAlertError && (
          <Alert
            severity="error"
            onClose={() => dispatch(onDisableAlertError({}))}
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <AlertTitle>Erro</AlertTitle>
            {alertErrorMessage}
          </Alert>
        )}
      </FlexibleStack>
    </MainContainer>
  );
};

export default Index;
