import { Grid, InputAdornment, Typography } from '@mui/material';
import { TextField, TextFieldIcon } from '../../@condofy-components/textField';
import { Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import { FlexibleBox, MainContainer, SubContainer } from '../../global/styles/style';
import { phoneMask } from '../../utils/masks';
import styled, { css } from 'styled-components';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import useWindowDimensions, { useAppDispatch, useAppSelector } from '../../hooks';
import { auth, onEmailChange, onNameChange, onPhoneChange, registerNewUser, resendEmailToken, testEmailWithoutLoading, onEmailOrCpfChange, updatePhone, resendPhoneToken, confirmPhone, logoutAuth, logoutAuthPhone, resetState } from '../../redux/authSlice';
import { goesToStep } from '../../redux/stepperSlice';
import { useEffect, useState } from 'react';
import { ActionButton } from '../../@condofy-components/actionButton';
import { Code } from '../../@condofy-components/codeInput';
import { onActiveAlertError, onActiveAlertSuccess } from '../../redux/alertSlice';
import colors from '../../global/theme/colors';
import { cleanStringIfIfCpf, isEmail } from '../../utils';
import { onCpfChange } from '../../redux/userSlice';
import { GridFlex } from './style';
import { condofy } from '../../api/base';

const fieldTypography = css`
  .MuiInputAdornment-root {
    color: red;
    font-family: 'inter', sans-serif !important;
    font-size: 14px;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  ${fieldTypography}
`;

enum Status {
  DEFAULT = 0,
  SENT = 1,
  CONFIRMED = 2,
  DENIED = 3
}

export const Register = () => {
  const { phone, email, userName, emailOrCpf, id } = useAppSelector(auth);
  const dispatch = useAppDispatch();
  const [emailStatus, setEmailStatus] = useState(Status.DEFAULT);
  const [phoneStatus, setPhoneStatus] = useState(Status.DEFAULT);
  const [code, setCode] = useState('');
  const { width } = useWindowDimensions();
  const [timeLeftEmail, setTimeLeftEmail] = useState(40);
  const [buttonDisabledEmail, setButtonDisabledEmail] = useState(true);
  const [timeLeftPhone, setTimeLeftPhone] = useState(40);
  const [buttonDisabledPhone, setButtonDisabledPhone] = useState(true);
  const [shouldCheckEmail, setShouldCheckEmail] = useState(false);

  useEffect(() => {
    let intervalId: any;
    if (buttonDisabledEmail) {
      intervalId = setInterval(() => {
        if (timeLeftEmail > 0) {
          setTimeLeftEmail(timeLeftEmail - 1);
        } else {
          setButtonDisabledEmail(false);
          clearInterval(intervalId);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [buttonDisabledEmail, timeLeftEmail]);

  useEffect(() => {
    let intervalId: any;
    if (buttonDisabledPhone) {
      intervalId = setInterval(() => {
        if (timeLeftPhone > 0 && buttonDisabledPhone) {
          setTimeLeftPhone(timeLeftPhone - 1);
        } else {
          setButtonDisabledPhone(false);
          clearInterval(intervalId);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [buttonDisabledPhone, timeLeftPhone]);

  useEffect(() => {
    if (isEmail(emailOrCpf)) {
      dispatch(onEmailChange(emailOrCpf));
    } else {
      dispatch(onCpfChange(emailOrCpf));
    }
    dispatch(onEmailOrCpfChange(''))
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendToken = () => {
    dispatch(resendEmailToken({ email }))
      .then(() => {
        setButtonDisabledEmail(true);
        setTimeLeftEmail(40);
        dispatch(onActiveAlertSuccess("Email reenviado com sucesso."))
      }).catch(() => {
        dispatch(onActiveAlertError("Falha ao reenviar o email, tente novamente."))
      })
  }

  const checkEmail = () => {
    dispatch(testEmailWithoutLoading({ email }))
      .then((response: any) => {
        console.log(response);
        if (!response.payload || !response.payload.data) {
          return
        }
        const treatedResponse = response.payload.data.data

        console.log(treatedResponse)
        if (treatedResponse.emailConfirmed) {
          setShouldCheckEmail(false);
          setEmailStatus(Status.CONFIRMED);
        }
      });
  }

  const verifyEmail = async () => {
    try {
      if (!email) { return false }

      const answer = await condofy.get(
        `/users/${cleanStringIfIfCpf(email)}/state`
      );

      const treatedResponse = answer.data.data
      console.log(treatedResponse)
      if (treatedResponse.id.length > 0) {
        setEmailStatus(Status.DENIED);
        dispatch(onActiveAlertError('Email já existente.'))
        return false;
      }
    } catch (error) {
      setEmailStatus(Status.DEFAULT);
      return true;
    }
  }

  const register = async () => {
    const test = await verifyEmail();
    if (!email || !userName || !test) {
      return;
    }
    dispatch(registerNewUser({ email, name: userName }))
      .then((response: any) => {
        const treatedResponse = response.payload.data.data
        if (treatedResponse) {
          setEmailStatus(Status.SENT)
          dispatch(onActiveAlertSuccess('Email de confirmação enviado com sucesso.'))
          setShouldCheckEmail(true);
          setButtonDisabledEmail(true);
          setTimeLeftEmail(40)
        }
      }).catch((error: any) => {
        dispatch(onActiveAlertError('Erro ao enviar o código.'))
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (shouldCheckEmail) {
        checkEmail();
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [shouldCheckEmail]);

  const registerPhone = () => {
    if (!phone || !id) { return }
    dispatch(updatePhone({ phone, id }))
      .then((response) => {
        setPhoneStatus(Status.SENT);
        dispatch(onActiveAlertSuccess('Email de confirmação enviado com sucesso.'))
        setTimeLeftPhone(40)
      }).catch((err) => {
        dispatch(onActiveAlertError('Falha ao enviar o código tente novamente.'))
      })
  };

  useEffect(() => {
    if (code.length === 6) {
      sendConfirmationCode();
    }
  }, [code])


  const sendConfirmationCode = () => {
    console.log({ phone, id, code })
    dispatch(confirmPhone({ phone, id: id as string, token: code }))
      .then((response) => {
        console.log({ response })
        if (response.payload) {
          dispatch(onActiveAlertSuccess('Telefone confirmado com sucesso.'));
          setPhoneStatus(Status.CONFIRMED);
          dispatch(goesToStep(5));
        } else {
          dispatch(onActiveAlertError('Erro ao confirmar o código, confirme e tente novamente.'))
        }
      }).catch(() => (dispatch(onActiveAlertError('Erro ao confirmar o código, confirme e tente novamente.'))))
  };

  const resendSms = () => {
    setButtonDisabledPhone(true);
    setTimeLeftPhone(40);
    setPhoneStatus(Status.SENT);
    dispatch(resendPhoneToken({ email }))
      .then(() => { dispatch(onActiveAlertSuccess('Código reenviado com sucesso.')) })
      .catch(() => (dispatch(onActiveAlertError('Erro ao reenviar o código, tente novamente.'))))
  };

  const handleReturnToStep0 = () => {
    dispatch(resetState())
    dispatch(goesToStep(0))
  }

  return (
    <MainContainer data-testid='register'>
      <SubContainer>
        <Header style={{ margin: 0 }}>Dados Pessoais</Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          Precisamos saber um pouco mais sobre você, vamos lá?
        </SubHeader>
        <FlexibleBox hasIcon>
          <Label>Seu Nome</Label>
          <TextField
            data-testid='register-option-your-name'
            normalWidth={'92%'}
            mobileWidth={'92%'}
            placeholder="Seu Nome"
            sx={{ marginTop: '16px' }}
            value={userName}
            onChange={(e: any) => dispatch(onNameChange(e.target.value))}
          />
          <Label style={{ marginTop: '16px' }}>Seu Email</Label>
          <TextFieldIcon
            data-testid='register-option-email'
            checkStatus={emailStatus}
            disabled={!userName || (emailStatus === 1 && timeLeftEmail !== 0) || emailStatus === 2}
            normalWidth={'92%'}
            mobileWidth={'92%'}
            placeholder="Seu Email"
            sx={{ marginTop: '16px' }}
            value={email}
            onChange={(e: any) => {
              if (emailStatus !== 0) {
                dispatch(logoutAuth({}))
                setEmailStatus(Status.DEFAULT)
                setButtonDisabledEmail(false)
                setTimeLeftEmail(40)
                setShouldCheckEmail(false)
              }
              dispatch(onEmailChange(e.target.value))
            }}
          />
          <GridFlex container>
            <Grid item xs={width < 926 ? 12 : 4} sx={{ textAlign: 'center' }}>
              {emailStatus === 1
                ?
                (<ActionButton
                  title={`Reenviar${timeLeftEmail > 0 ? ` em ${timeLeftEmail}` : ''}`}
                  disabled={buttonDisabledEmail}
                  onClick={resendToken} />)
                :
                (<ActionButton
                  testid='confirm-email'
                  title={emailStatus === 2 ? 'Confirmado' : 'Confirmar email'}
                  disabled={!email || !userName || emailStatus === 3 || emailStatus === 2}
                  onClick={() => register()} />)
              }
            </Grid>
            <Grid item xs={width < 926 ? 12 : 5} sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontSize: '14px' }}>{emailStatus === 1 ? 'Verifique seu email' : emailStatus !== 3 ? '' : 'Ops! Este email já existe'}</Typography>
            </Grid>
            <Grid item xs={width < 926 ? 12 : 3} sx={{ textAlign: 'center' }}>
              <Typography
                onClick={() => {
                  dispatch(onEmailOrCpfChange(email));
                  dispatch(goesToStep(1))
                }}
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  color: colors.brandBlue.BB600,
                  cursor: 'pointer',
                }}
              >{emailStatus !== 3 ? '' : 'Clique para login'}</Typography>
            </Grid>
          </GridFlex>
          <Label style={{ marginTop: '16px' }}>Seu Telefone</Label>
          <TextFieldIcon
            data-testid='register-option-your-phone'
            disabled={!userName || emailStatus !== 2 || (phoneStatus === 1 && timeLeftPhone !== 0)}
            checkStatus={phoneStatus}
            InputProps={{
              startAdornment: (
                <StyledInputAdornment
                  sx={{
                    '& .MuiTypography-root': {
                      fontFamily: 'inter, sans-serif',
                      fontSize: '14px',
                    },
                  }}
                  position="start"
                >
                  +55
                </StyledInputAdornment>
              ),
            }}
            sx={{ marginTop: '16px' }}
            normalWidth={'92%'}
            mobileWidth={'92%'}
            value={phone.length === 0 ? '' : ` ${phoneMask(phone)}`}
            onChange={(e: any) => {
              if (phoneStatus !== 0) {
                dispatch(logoutAuthPhone({}))
                setPhoneStatus(Status.DEFAULT)
                setButtonDisabledPhone(false)
                setTimeLeftPhone(40)
              }
              dispatch(onPhoneChange(e.target.value))
            }}
          />
          {phoneStatus === 1
            ? (<ActionButton
              title={`Reenviar${timeLeftPhone > 0 ? ` em ${timeLeftPhone}` : ''}`}
              disabled={buttonDisabledPhone}
              onClick={resendSms}
              style={{ marginTop: '16px', marginBottom: '16px' }}
            />)
            : (<ActionButton
              testid='confirm-phone'
              title={phoneStatus === 2 ? 'Confirmado' : 'Confirmar telefone'}
              disabled={emailStatus !== 2 || !userName || phone.length < 16}
              onClick={() => registerPhone()}
              style={{ marginTop: '16px', marginBottom: '16px', padding: '10px' }}
            />)

          }
          {phoneStatus === 1 && (<Code
            value={code}
            onChange={setCode}
            onComplete={() => { }}
          />)}
        </FlexibleBox>
      </SubContainer>
      <NextAndPrevComponents
        disableNext={!phone ||
          phoneStatus !== Status.CONFIRMED ||
          !userName ||
          emailStatus !== Status.CONFIRMED
        }
        onNext={register}
        onReturn={handleReturnToStep0}
      />
    </MainContainer >
  );
};
